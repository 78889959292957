import { combineReducers } from "redux";
import reducers from '../reducers';
import migrateReducers from './migration/migrateReducers';
import globalReducers from './global/globalReducers';

export default combineReducers({
    ...reducers,
    global: globalReducers,
    migration: migrateReducers,
});
