import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import './assets/base.css';
import Main from './Views/Main';
import store from "./redux/store";
import './App.css';

const rootElement = document.getElementById('root');

const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Component />
        </BrowserRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./Views/Main', () => {
    const NextApp = require('./Views/Main').default;
    renderApp(NextApp);
  });
}
unregister();

// registerServiceWorker();

