import cookie from 'react-cookies';
import { _doEncryptObj, _doDecryptObj } from '../config/encryption';
const assign = require('lodash/assign');

export const setSessionLogin = (token, obj) => {    
    const profileAccount = assign({
        [`app_account_token`]: token,
        [`app_account_fullname`]: obj.fullName,
        [`app_account_username`]: obj.username,
    });
    
    const isLogin = assign({
        [`app_auth_authenticated`]: true,
    });

    setCookie("account", profileAccount);
    setCookie("isLogin", isLogin);
}

export const setCookie = async (cookieName, objCookies, expired) => {
    const secret = _doEncryptObj(objCookies);
    cookie.save(cookieName, secret.toString(), { path: "/", maxAge: expired });
    return cookie;
};

export const getCookie = (cookieName, str = "") => {
    if (cookie.load(cookieName) !== undefined) {
        const decryptedData = _doDecryptObj(cookie.load(cookieName));
        if (str !== "") {
            return decryptedData[str];
        } else {
            return decryptedData;
        }
    }

    return null;
};

export const clearCookie = (cookieName) => cookie.remove(cookieName, { path: "/" });

export const removeDataUser = () => {
    clearCookie("account");
    clearCookie("isLogin");

    localStorage.clear();
};