import { toast } from 'react-toastify';
import { STATUS } from './constants';

export const notifMessage = (status, label) => {
    if (status === STATUS.SUCCESS) {
        toast.success(`${label} Success`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    } else {
        toast.error(`${label} Failed`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
}