export const respData = (data = '') => {
    return {
        status: true,
        result: data
    }
}

export const respError = {
    status: false,
    result: ''
}