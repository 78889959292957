import axios from "axios";
const BASE_URL = 'https://api-dev-2636.k8s-staging-ovh.jubelio.com';
// const BASE_URL2 = process.env.REACT_APP_WMS_MIGRATE_APP2 || 'https://api2-lb.jubelio.com';

// const BASE_URL = 'https://api-lb.jubelio.com';
const BASE_URL2 = 'https://api-wms-release-v2.staging-k8s.jubelio.com';

export const instanceWmsApp = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "accept": "application/json",
  },
});

export const instanceWmsApp2 = axios.create({
  baseURL: BASE_URL2,
  headers: {
    "Content-Type": "application/json",
    "accept": "application/json",
  },
});
