import { createSlice } from "@reduxjs/toolkit";

import { InitialState } from './globalInitials';

export const GlobalReducers = createSlice({
  name: 'Global',
  initialState: InitialState,
  reducers: {
    // Handling state global
    HandleChangeStateProfile: (state, action) => {
      state.fullName = action.payload.fullName;
      state.username = action.payload.username;
    },

    // Handling state global
    HandleChangeStateGlobal: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
  
    // Handling Change Modal
    HandleChangeModal: (state, action) => {
        state.modal = action.payload.modal;
        state.sizeModal = action.payload.sizeModal;
        state.titleModal = action.payload.titleModal;
        state.typeModal = action.payload.typeModal;
        state.hideClose = action.payload.hideClose;
    },
  }
});

export default GlobalReducers.reducer;
