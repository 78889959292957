import { 
    getTotalSKU,
    getTotalPo,
    getTotalTransfer,
    getTotalTransferTransit,
    getTotalTransferShipped,
    getTotalTransferShipped2,
    getTotalSalesReturn,
    getTotalOnlineReturnRejected,
    getTotalOnlineReturnApproved,
    getTotalOnlineReturnApproved2,
    getTotalBill,
    getTotalInvoice,
    getTotalAdjustment,
    getTotalPurchaseReturn,
    getTotalOrderNotPaid,
    getTotalOrderCancel,
    getTotalOrderReady,
    getTotalOrderConfirmPick,
    getTotalOrderReadyShip,
    getTotalOrderShipped,
    getTotalOrderShipped2,

    // Start Check Compare Data Order Shipped
    checkShippedOrderAppWithReadyToShipApp2,
    checkDuplicateReadyToShipWithShippedOrder,
    checkWarehouseStatusReadyToShipNotShowInReadyToShip,
    // End Start Check Compare Data Order Shipped

    getTotalOrderCompleted,
    getTotalOrderFailed,
    getMPIntegration,
    turnOffIntegration,

    // Readonly Check
    transferReadonlyCheck,
    salesReturnReadonlyCheck,
    enableTriggerCheck,
    billReadonlyCheck,
} from '../../service/migration';
import { respData, respError } from '../../helpers/responses';

let response = {};

export const totalSku = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalSKU(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalPo = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalPo(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalTransfer = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalTransfer(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalTransferTransit = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalTransferTransit(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalTransferShipped = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalTransferShipped(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalTransferShipped2 = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalTransferShipped2(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalSalesReturn = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalSalesReturn(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalOnlineReturnRejected = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOnlineReturnRejected(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalOnlineReturnApproved = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOnlineReturnApproved(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalOnlineReturnApproved2 = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOnlineReturnApproved2(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalBill = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalBill(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalInvoice = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalInvoice(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalAdjustment = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalAdjustment(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalPurchaseReturn = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalPurchaseReturn(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalOrderNotPaid = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOrderNotPaid(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalOrderCancel = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOrderCancel(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalOrderReady = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOrderReady(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalOrderConfirmPick = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOrderConfirmPick(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalOrderReadyShip = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOrderReadyShip(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalOrderShipped = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOrderShipped(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalOrderShipped2 = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOrderShipped2(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

// Start Check Compare Data Order Shipped
export const totalShippedOrderAppWithReadyToShipApp2 = async (token) => {
    try {
        // Get Service
        const data = await checkShippedOrderAppWithReadyToShipApp2(token);

        if (data) response = respData(parseFloat(data.data.totalcount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalDuplicateReadyToShipWithShippedOrder = async (token) => {
    try {
        // Get Service
        const data = await checkDuplicateReadyToShipWithShippedOrder(token);

        if (data) response = respData(parseFloat(data.data.totalcount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalWarehouseStatusReadyToShipNotShowInReadyToShip = async (token) => {
    try {
        // Get Service
        const data = await checkWarehouseStatusReadyToShipNotShowInReadyToShip(token);

        if (data) response = respData(parseFloat(data.data.totalcount));
    } catch (error) {
        response = respError;
    }

    return response;
}
// End Check Compare Data Order Shipped

export const totalOrderCompleted = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOrderCompleted(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalOrderFailed = async (token, service) => {
    try {
        // Get Service
        const data = await getTotalOrderFailed(token, service);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const mPIntegration = async (token, service) => {
    try {
        // Get Service
        const data = await getMPIntegration(token, service);

        if (data) response = respData(data.data);
    } catch (error) {
        response = respError;
    }

    return response;
}

export const turnOffMp = async (datas, token, service) => {
    try {
        // Get Service
        const data = await turnOffIntegration(datas, token, service);

        if (data) response = respData(data.data.status);
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalBillReadonlyCheck = async (token) => {
    try {
        // Get Service
        const data = await billReadonlyCheck(token);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalTransferReadonlyCheck = async (token) => {
    try {
        // Get Service
        const data = await transferReadonlyCheck(token);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const totalSalesReturnReadonlyCheck = async (token) => {
    try {
        // Get Service
        const data = await salesReturnReadonlyCheck(token);

        if (data) response = respData(parseFloat(data.data.totalCount));
    } catch (error) {
        response = respError;
    }

    return response;
}

export const getEnableTriggerCheck = async (token) => {
    try {
        // Get Service
        const data = await enableTriggerCheck(token);

        if (data) response = respData(data.data);
    } catch (error) {
        response = respError;
    }

    return response;
}