import React from 'react';
import { Button, CardBody, CardHeader, Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import { 
    LABEL,
    STEPS,
    STEP_PROCESS
} from '../../../helpers/constants';
import { 
    HandleChangeStateRetryMigrate, 
    setStepMigration 
  } from '../../../redux/migration/migrateActions';
  import { getDataWmsMigration } from '../../../service/migration';
import TableBeforeAfter from './TableBeforeAfter';
import { executeWMS } from '../../../redux/migration/migrateActions';

library.add(
    faAngleLeft,
);

const Block4 = () => {
    const dispatch = useDispatch();
    const stateMigration = useSelector((state) => state.migration);

    const { stepMigration, waitProcessLabel, retryMigration, tempPayload, token } = stateMigration;

    const btnRetry = async (stateName) => {
        // Set BtnRetry false
        dispatch(HandleChangeStateRetryMigrate({
          name: stateName,
          value: false,
        }));
    
        // Service get wms
        await getDataWmsMigration(token)
        .then((response) => {
            const { step_migration } = response.data.data;
    
            // Set State Step Migration
            dispatch(setStepMigration(tempPayload, step_migration, token));
        }).catch(() => {
            // Just for Retry
            dispatch(HandleChangeStateRetryMigrate({
                name: stateName,
                value: true,
            }));
        });
    }

    const btnExecute = async (stateName) => {
        let obj = {};
        if (stateName === 'turnOnIntegration') {
            obj.name = STEPS.TURN_ON_INTEGRATION;
        } else {
            obj.name = STEPS.TURN_ON_SYNC_ORDER;
        }

        // Dispatch execute WMS
        dispatch(executeWMS(obj));
    }

    return (
        <CardBody>
            <Table className="mb-0" bordered>
                <thead>
                <tr>
                    <th style={{width: "71%"}}>Name</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">{LABEL.DEFAULT_BIN_CHECK}</th>
                    <td>
                        {stepMigration.defaultBinCheck === STEP_PROCESS.FINISHED ? (
                            <Button className="mb-2 me-2" active color="info">
                                {STEP_PROCESS.FINISHED}
                            </Button>
                        ) : (stepMigration.defaultBinCheck === STEP_PROCESS.FAILED && retryMigration.defaultBinCheck === true) ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('defaultBinCheck')}>{STEP_PROCESS.RETRY}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.defaultBinCheck ? waitProcessLabel.defaultBinCheck : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                </tr>
                </tbody>
            </Table>
            <br />
            <CardHeader>
                <i className="header-icon lnr-license icon-gradient bg-plum-plate"> </i>
                {LABEL.DATA_AFTER_MIGRATION}
                <div className="btn-actions-pane-right">
                    {(stepMigration?.totalOrderFailedAfterMigration === 0 || stepMigration?.totalOrderFailedAfterMigration > 0) ? (
                        <Button color='primary'>{STEP_PROCESS.PASS}</Button>
                    ) : (
                        <Button className="mb-2 me-2" active color="warning">
                            <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                        </Button>
                    )}
                </div>
            </CardHeader>
            {stepMigration.defaultBinCheck === STEP_PROCESS.FINISHED && (
                <TableBeforeAfter />
            )}
            <br />
            <Table className="mb-0" bordered>
                <thead>
                    <tr>
                        <th style={{width: "71%"}}>Name</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{LABEL.BILL_READONLY_CHECK}</th>
                        <td>
                            {stepMigration.billReadonlyCheck === STEP_PROCESS.FINISHED ? (
                                <Button className="mb-2 me-2" active color="info">
                                    {STEP_PROCESS.FINISHED}
                                </Button>
                            ) : (stepMigration.billReadonlyCheck === STEP_PROCESS.FAILED && retryMigration.billReadonlyCheck === true) ? (
                                <>
                                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('billReadonlyCheck')}>{STEP_PROCESS.RETRY}</Button>
                                </>
                            ) : (
                                <Button className="mb-2 me-2" active color="warning">
                                    <Spinner animation="border" size="sm"></Spinner>
                                    {' '} {waitProcessLabel.billReadonlyCheck ? waitProcessLabel.billReadonlyCheck : STEP_PROCESS.WAITING}
                                </Button>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{LABEL.TRANSFER_READONLY_CHECK}</th>
                        <td>
                            {stepMigration.transferReadonlyCheck === STEP_PROCESS.FINISHED ? (
                                <Button className="mb-2 me-2" active color="info">
                                    {STEP_PROCESS.FINISHED}
                                </Button>
                            ) : (stepMigration.transferReadonlyCheck === STEP_PROCESS.FAILED && retryMigration.transferReadonlyCheck === true) ? (
                                <>
                                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('transferReadonlyCheck')}>{STEP_PROCESS.RETRY}</Button>
                                </>
                            ) : (
                                <Button className="mb-2 me-2" active color="warning">
                                    <Spinner animation="border" size="sm"></Spinner>
                                    {' '} {waitProcessLabel.transferReadonlyCheck ? waitProcessLabel.transferReadonlyCheck : STEP_PROCESS.WAITING}
                                </Button>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{LABEL.SALES_RETURN_READONLY_CHECK}</th>
                        <td>
                            {stepMigration.salesReturnReadonlyCheck === STEP_PROCESS.FINISHED ? (
                                <Button className="mb-2 me-2" active color="info">
                                    {STEP_PROCESS.FINISHED}
                                </Button>
                            ) : (stepMigration.salesReturnReadonlyCheck === STEP_PROCESS.FAILED && retryMigration.salesReturnReadonlyCheck === true) ? (
                                <>
                                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('salesReturnReadonlyCheck')}>{STEP_PROCESS.RETRY}</Button>
                                </>
                            ) : (
                                <Button className="mb-2 me-2" active color="warning">
                                    <Spinner animation="border" size="sm"></Spinner>
                                    {' '} {waitProcessLabel.salesReturnReadonlyCheck ? waitProcessLabel.salesReturnReadonlyCheck : STEP_PROCESS.WAITING}
                                </Button>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{LABEL.ENABLE_TRIGGER_CHECK}</th>
                        <td>
                            {stepMigration.enableTriggerCheck === STEP_PROCESS.FINISHED ? (
                                <Button className="mb-2 me-2" active color="info">
                                    {STEP_PROCESS.FINISHED}
                                </Button>
                            ) : (stepMigration.enableTriggerCheck === STEP_PROCESS.FAILED && retryMigration.enableTriggerCheck === true) ? (
                                <>
                                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('enableTriggerCheck')}>{STEP_PROCESS.RETRY}</Button>
                                </>
                            ) : (
                                <Button className="mb-2 me-2" active color="warning">
                                    <Spinner animation="border" size="sm"></Spinner>
                                    {' '} {waitProcessLabel.enableTriggerCheck ? waitProcessLabel.enableTriggerCheck : STEP_PROCESS.WAITING}
                                </Button>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{LABEL.UPDATE_ACCOUNT_SUPPORT}</th>
                        <td>
                            {stepMigration.updateAccountSupport === STEP_PROCESS.FINISHED ? (
                                <Button className="mb-2 me-2" active color="info">
                                    {STEP_PROCESS.FINISHED}
                                </Button>
                            ) : (stepMigration.updateAccountSupport === STEP_PROCESS.FAILED && retryMigration.updateAccountSupport === true) ? (
                                <>
                                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('updateAccountSupport')}>{STEP_PROCESS.RETRY}</Button>
                                </>
                            ) : (
                                <Button className="mb-2 me-2" active color="warning">
                                    <Spinner animation="border" size="sm"></Spinner>
                                    {' '} {waitProcessLabel.updateAccountSupport ? waitProcessLabel.updateAccountSupport : STEP_PROCESS.WAITING}
                                </Button>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{LABEL.TURN_ON_INTEGRATION}</th>
                        <td>
                            {stepMigration.turnOnIntegration === STEP_PROCESS.FINISHED ? (
                                <Button className="mb-2 me-2" active color="info">
                                    {STEP_PROCESS.FINISHED}
                                </Button>
                            ) : stepMigration.turnOnIntegration === STEP_PROCESS.FAILED ? (
                                <>
                                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnExecute('turnOnIntegration')}>{STEP_PROCESS.RETRY}</Button>
                                </>
                            ) : stepMigration.turnOnIntegration === STEP_PROCESS.WAITING ? (
                                <Button className="mb-2 me-2" active color="warning">
                                    <Spinner animation="border" size="sm"></Spinner>
                                    {' '} {waitProcessLabel.turnOnIntegration ? waitProcessLabel.turnOnIntegration : STEP_PROCESS.WAITING}
                                </Button>
                            ) : (
                                <Button className="mb-2 me-2" variant="outline-dark" onClick={() => btnExecute('turnOnIntegration')}>Execute</Button>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{LABEL.TURN_ON_SYNC_ORDER}</th>
                        <td>
                            {stepMigration.turnOnSyncOrder === STEP_PROCESS.FINISHED ? (
                                <Button className="mb-2 me-2" active color="info">
                                    {STEP_PROCESS.FINISHED}
                                </Button>
                            ) : stepMigration.turnOnSyncOrder === STEP_PROCESS.FAILED ? (
                                <>
                                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnExecute('turnOnSyncOrder')}>{STEP_PROCESS.RETRY}</Button>
                                </>
                            ) : stepMigration.turnOnSyncOrder === STEP_PROCESS.WAITING ? (
                                <Button className="mb-2 me-2" active color="warning">
                                    <Spinner animation="border" size="sm"></Spinner>
                                    {' '} {waitProcessLabel.turnOnSyncOrder ? waitProcessLabel.turnOnSyncOrder : STEP_PROCESS.WAITING}
                                </Button>
                            ) : (
                                <Button className="mb-2 me-2" variant="outline-dark" onClick={() => btnExecute('turnOnSyncOrder')}>Execute</Button>
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </CardBody>
    )
}

export default Block4