import { createSlice } from "@reduxjs/toolkit";

import { InitialState } from './migrateInitials';

export const MigrateReducers = createSlice({
  name: 'Migrasi',
  initialState: InitialState,
  reducers: {
    // Handle state global
    HandleChangeStateMigrate: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },

    HandleResetStateMigration: (state) => {
      state.ownerEmail = "";
      state.companyName = "";
      state.stepMigration = InitialState.stepMigration;
      state.waitProcessLabel = InitialState.waitProcessLabel;
      state.retryMigration = InitialState.retryMigration;
      state.skipMigration = InitialState.skipMigration;
      state.tempPayload = InitialState.tempPayload;
    },

    // Handle Change Konfirmasi
    HandleChangeConfirm: (state, action) => {
        state.companyName = action.payload.companyName;
        state.ownerEmail = action.payload.ownerEmail;
    },

    // Handle state step migration
    HandleChangeStateStepMigrate: (state, action) => {
      state.stepMigration[action.payload.name] = action.payload.value;
    },

    // Handle state wait process label
    HandleChangeStateWaitProcessLabel: (state, action) => {
      state.waitProcessLabel[action.payload.name] = action.payload.value;
    },

    // Handle state retry migration
    HandleChangeStateRetryMigrate: (state, action) => {
      state.retryMigration[action.payload.name] = action.payload.value;
    },

    // Handle state skip migration
    HandleChangeStateSkipMigrate: (state, action) => {
      state.skipMigration[action.payload.name] = action.payload.value;
    },

    // Handle state retry migration
    HandleUpdateStepMigration: (state, action) => {
      state.stepMigration = {
        dataBeforeMigration: action.payload.data_before_migration || '',
        
        // Step 1
        totalSkuBeforeMigration: action.payload.total_sku_before_migration,
        totalPoBeforeMigration: action.payload.total_po_before_migration,
        totalTransferBeforeMigration: action.payload.total_transfer_before_migration,
        totalTransferTransitBeforeMigration: action.payload.total_transfer_transit_before_migration,
        totalTransferShippedBeforeMigration: action.payload.total_transfer_shipped_before_migration,
        totalSalesReturnBeforeMigration: action.payload.total_sales_return_before_migration,
        totalOnlineReturnRejectedBeforeMigration: action.payload.total_online_return_rejected_before_migration,
        totalOnlineReturnApprovedBeforeMigration: action.payload.total_online_return_approved_before_migration,
        totalBillBeforeMigration: action.payload.total_bill_before_migration,
        totalInvoiceBeforeMigration: action.payload.total_invoice_before_migration,
        totalAdjustmentBeforeMigration: action.payload.total_adjustment_before_migration,
        totalPurchaseReturnBeforeMigration: action.payload.total_purchase_return_before_migration,
        totalOrderNotPaidBeforeMigration: action.payload.total_order_not_paid_before_migration,
        totalOrderCancelBeforeMigration: action.payload.total_order_cancel_before_migration,
        totalOrderReadyToPickBeforeMigration: action.payload.total_order_ready_to_pick_before_migration,
        totalOrderConfirmPickBeforeMigration: action.payload.total_order_confirm_pick_before_migration,
        totalOrderReadyToShipBeforeMigration: action.payload.total_order_ready_to_ship_before_migration,
        totalOrderShippedBeforeMigration: action.payload.total_order_shipped_before_migration,
        totalOrderCompletedBeforeMigration: action.payload.total_order_completed_before_migration,
        totalOrderFailedBeforeMigration: action.payload.total_order_failed_before_migration,

        // Step 2
        migrateToApp: action.payload.migrate_to_app || '',
        msgMigrateToApp: action.payload.migration_process_app_message || '',
        valMigrateToApp: action.payload.migration_process_app,
        turnOffIntegration: action.payload.turn_off_integration || '',
        signedAsWms: action.payload.signed_as_wms || '',
        wmsMigrationProcess: action.payload.wms_migration_process || '',
        msgWmsMigrationProcess: action.payload.migration_process_message || '',
        valWmsMigrationProcess: action.payload.migration_process,
        defaultBinItemMovementProcess: action.payload.default_bin_item_movement_process || '',
        defaultBinProcess: action.payload.default_bin_process || '',
        employeeMapping: action.payload.employee_mapping || '',
        orderMappingToWmsStatus: action.payload.order_mapping_to_wms_status || '',
        binCheckInTransaction: action.payload.bin_check_in_transaction || '',
        snBnMappingToSnPsn: action.payload.snbn_mapping_to_snpsn || '',
        snBnMovetoSnm: action.payload.snbn_move_to_snm || '',

        // Step 3
        billCheck: action.payload.bill_check || '',
        adjustmentCheck: action.payload.adjustment_check || '',
        purchaseReturnCheck: action.payload.purchase_return_check || '',
        orderCheck: action.payload.order_check || '',
        orderItemBundleCheck: action.payload.order_item_bundle_check || '',
        salesReturnCheck: action.payload.sales_return_check || '',
        transferCheck: action.payload.transfer_check || '',
        archiveItemCheck: action.payload.archive_item_check || '',
        snBnRackCheck: action.payload.snbn_rack_check || '',
        snBnIllegalCheck: action.payload.snbn_illegal_check || '',
        totalSnbnIllegal: action.payload.total_snbn_illegal || 0,

        // Step 4
        defaultBinCheck: action.payload.default_bin_check || '',
        dataAfterMigration: action.payload.data_after_migration || '',

        // Step 5
        totalSkuAfterMigration: action.payload.total_sku_after_migration,
        totalPoAfterMigration: action.payload.total_po_after_migration,
        totalTransferAfterMigration: action.payload.total_transfer_after_migration,
        totalTransferTransitAfterMigration: action.payload.total_transfer_transit_after_migration,
        totalTransferShippedAfterMigration: action.payload.total_transfer_shipped_after_migration,
        totalSalesReturnAfterMigration: action.payload.total_sales_return_after_migration,
        totalOnlineReturnRejectedAfterMigration: action.payload.total_online_return_rejected_after_migration,
        totalOnlineReturnApprovedAfterMigration: action.payload.total_online_return_approved_after_migration,
        totalBillAfterMigration: action.payload.total_bill_after_migration,
        totalInvoiceAfterMigration: action.payload.total_invoice_after_migration,
        totalAdjustmentAfterMigration: action.payload.total_adjustment_after_migration,
        totalPurchaseReturnAfterMigration: action.payload.total_purchase_return_after_migration,
        totalOrderNotPaidAfterMigration: action.payload.total_order_not_paid_after_migration,
        totalOrderCancelAfterMigration: action.payload.total_order_cancel_after_migration,
        totalOrderReadyToPickAfterMigration: action.payload.total_order_ready_to_pick_after_migration,
        totalOrderConfirmPickAfterMigration: action.payload.total_order_confirm_pick_after_migration,
        totalOrderReadyToShipAfterMigration: action.payload.total_order_ready_to_ship_after_migration,
        totalOrderShippedAfterMigration: action.payload.total_order_shipped_after_migration,

        // Start Check Compare Data Order Shipped
        totalShippedOrderAppWithReadyToShipApp2: action.payload.total_shipped_order_app_with_ready_to_ship_app2,
        totalDuplicateReadyToShipWithShippedOrder: action.payload.total_duplicate_ready_to_ship_with_shipped_order,
        totalWarehouseStatusReadyToShipNotShowInReadyToShip: action.payload.total_warehouse_status_ready_to_ship_not_show_in_ready_to_ship,
        // End Check Compare Data Order Shipped

        totalOrderCompletedAfterMigration: action.payload.total_order_completed_after_migration,
        totalOrderFailedAfterMigration: action.payload.total_order_failed_after_migration,

        // Step 6
        billReadonlyCheck: action.payload.bill_readonly_check || '',
        transferReadonlyCheck: action.payload.transfer_readonly_check || '',
        salesReturnReadonlyCheck: action.payload.sales_return_readonly_check || '',
        enableTriggerCheck: action.payload.enable_trigger_check || '',
        updateAccountSupport: action.payload.update_account_support || '',
        turnOnIntegration: action.payload.turn_on_integration || '',
        turnOnSyncOrder: action.payload.turn_on_sync_order || '',
      };
    },

    // Handle clear state stepMigration and retryMigration
    HandleClearStepAndRetryMigration: (state, action) => {
      state.stepMigration = InitialState.stepMigration;
      state.retryMigration = InitialState.retryMigration;
    }
  }
});

export default MigrateReducers.reducer;
