import { STEPS } from '../../helpers/constants';

const mapSkipRetry = {
    dataBeforeMigration: false,
        
    // Step 1
    totalSkuBeforeMigration: false,
    totalPoBeforeMigration: false,
    totalTransferBeforeMigration: false,
    totalTransferTransitBeforeMigration: false,
    totalTransferShippedBeforeMigration: false,
    totalSalesReturnBeforeMigration: false,
    totalOnlineReturnRejectedBeforeMigration: false,
    totalOnlineReturnApprovedBeforeMigration: false,
    totalBillBeforeMigration: false,
    totalInvoiceBeforeMigration: false,
    totalAdjustmentBeforeMigration: false,
    totalPurchaseReturnBeforeMigration: false,
    totalOrderNotPaidBeforeMigration: false,
    totalOrderCancelBeforeMigration: false,
    totalOrderReadyToPickBeforeMigration: false,
    totalOrderConfirmPickBeforeMigration: false,
    totalOrderReadyToShipBeforeMigration: false,
    totalOrderShippedBeforeMigration: false,
    totalOrderCompletedBeforeMigration: false,
    totalOrderFailedBeforeMigration: false,

    // Step 2
    migrateToApp: false,
    turnOffIntegration: false,
    signedAsWms: false,
    wmsMigrationProcess: false,
    defaultBinItemMovementProcess: false,
    defaultBinProcess: false,
    employeeMapping: false,
    orderMappingToWmsStatus: false,
    binCheckInTransaction: false,
    snBnMappingToSnPsn: false,
    snBnMovetoSnm: false,

    // Step 3
    billCheck: false,
    adjustmentCheck: false,
    purchaseReturnCheck: false,
    orderCheck: false,
    orderItemBundleCheck: false,
    salesReturnCheck: false,
    transferCheck: false,
    archiveItemCheck: false,
    snBnRackCheck: false,
    totalSnbnIllegal: false,

    // Step 4
    defaultBinCheck: false,
    dataAfterMigration: false,

    // Step 5
    totalSkuAfterMigration: false,
    totalPoAfterMigration: false,
    totalTransferAfterMigration: false,
    totalTransferTransitAfterMigration: false,
    totalTransferShippedAfterMigration: false,
    totalSalesReturnAfterMigration: false,
    totalOnlineReturnRejectedAfterMigration: false,
    totalOnlineReturnApprovedAfterMigration: false,
    totalBillAfterMigration: false,
    totalInvoiceAfterMigration: false,
    totalAdjustmentAfterMigration: false,
    totalPurchaseReturnAfterMigration: false,
    totalOrderNotPaidAfterMigration: false,
    totalOrderCancelAfterMigration: false,
    totalOrderReadyToPickAfterMigration: false,
    totalOrderConfirmPickAfterMigration: false,
    totalOrderReadyToShipAfterMigration: false,
    totalOrderShippedAfterMigration: false,
    totalOrderCompletedAfterMigration: false,
    totalOrderFailedAfterMigration: false,

    // Step 6
    billReadonlyCheck: false,
    transferReadonlyCheck: false,
    salesReturnReadonlyCheck: false,
    enableTriggerCheck: false,
    updateAccountSupport: false,
    turnOnIntegration: false,
    turnOnSyncOrder: false,
}

const mapStepMigration = {
    dataBeforeMigration: '',
    
    // Step 1
    totalSkuBeforeMigration: '',
    totalPoBeforeMigration: '',
    totalTransferBeforeMigration: '',
    totalTransferTransitBeforeMigration: '',
    totalTransferShippedBeforeMigration: '',
    totalSalesReturnBeforeMigration: '',
    totalOnlineReturnRejectedBeforeMigration: '',
    totalOnlineReturnApprovedBeforeMigration: '',
    totalBillBeforeMigration: '',
    totalInvoiceBeforeMigration: '',
    totalAdjustmentBeforeMigration: '',
    totalPurchaseReturnBeforeMigration: '',
    totalOrderNotPaidBeforeMigration: '',
    totalOrderCancelBeforeMigration: '',
    totalOrderReadyToPickBeforeMigration: '',
    totalOrderConfirmPickBeforeMigration: '',
    totalOrderReadyToShipBeforeMigration: '',
    totalOrderShippedBeforeMigration: '',
    totalOrderCompletedBeforeMigration: '',
    totalOrderFailedBeforeMigration: '',

    // Step 2
    migrateToApp: '',
    valMigrateToApp: '',
    msgMigrateToApp: '',
    turnOffIntegration: '',
    signedAsWms: '',
    wmsMigrationProcess: '',
    msgWmsMigrationProcess: '',
    valWmsMigrationProcess: '',
    defaultBinItemMovementProcess: '',
    defaultBinProcess: '',
    employeeMapping: '',
    orderMappingToWmsStatus: '',
    binCheckInTransaction: '',
    snBnMappingToSnPsn: false,
    snBnMovetoSnm: false,

    // Step 3
    billCheck: '',
    adjustmentCheck: '',
    purchaseReturnCheck: '',
    orderCheck: '',
    orderItemBundleCheck: '',
    salesReturnCheck: '',
    transferCheck: '',
    archiveItemCheck: '',
    snBnRackCheck: '',
    totalSnbnIllegal: '',

    // Step 4
    defaultBinCheck: '',
    dataAfterMigration: '',

    // Step 5
    totalSkuAfterMigration: '',
    totalPoAfterMigration: '',
    totalTransferAfterMigration: '',
    totalTransferTransitAfterMigration: '',
    totalTransferShippedAfterMigration: '',
    totalSalesReturnAfterMigration: '',
    totalOnlineReturnRejectedAfterMigration: '',
    totalOnlineReturnApprovedAfterMigration: '',
    totalBillAfterMigration: '',
    totalInvoiceAfterMigration: '',
    totalAdjustmentAfterMigration: '',
    totalPurchaseReturnAfterMigration: '',
    totalOrderNotPaidAfterMigration: '',
    totalOrderCancelAfterMigration: '',
    totalOrderReadyToPickAfterMigration: '',
    totalOrderConfirmPickAfterMigration: '',
    totalOrderReadyToShipAfterMigration: '',
    totalOrderShippedAfterMigration: '',

    // Start Check Compare Data Order Shipped
    totalShippedOrderAppWithReadyToShipApp2: '',
    totalDuplicateReadyToShipWithShippedOrder: '',
    totalWarehouseStatusReadyToShipNotShowInReadyToShip: '',
    // End Check Compare Data Order Shipped

    totalOrderCompletedAfterMigration: '',
    totalOrderFailedAfterMigration: '',

    // Step 6
    billReadonlyCheck: '',
    transferReadonlyCheck: '',
    salesReturnReadonlyCheck: '',
    enableTriggerCheck: '',
    updateAccountSupport: '',
    turnOnIntegration: '',
    turnOnSyncOrder: '',
}

export const InitialState = {
    tenantId: '',
    companyId: '',
    companyName: '',
    ownerEmail: '',
    token: '',
    btnProcess: false,
    stepMigration: mapStepMigration,
    waitProcessLabel: mapStepMigration,
    retryMigration: mapSkipRetry,
    skipMigration: mapSkipRetry,
    tempPayload: [
        // Step 1
        {
            name: STEPS.DATA_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_SKU_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_PO_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_TRANSFER_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_TRANSFER_TRANSIT_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_TRANSFER_SHIPPED_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_SALES_RETURN_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ONLINE_RETURN_REJECTED_BEFORE_MIGRATION
        },
        {
            name: STEPS.TOTAL_ONLINE_RETURN_APPROVED_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_BILL_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_INVOICE_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ADJUSTMENT_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_PURCHASE_RETURN_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_NOT_PAID_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_CANCEL_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_READY_TO_PICK_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_CONFIRM_PICK_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_READY_TO_SHIP_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_SHIPPED_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_COMPLETED_BEFORE_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_FAILED_BEFORE_MIGRATION,
        },

        // Step 2
        {
            name: STEPS.MIGRATE_TO_APP,
        },
        {
            name: STEPS.TURN_OFF_INTEGRATION,
        },
        {
            name: STEPS.SIGNED_AS_WMS,
        },
        {
            name: STEPS.WMS_MIGRATION_PROCESS,
        },
        {
            name: STEPS.DEFAULT_BIN_ITEM_MOVEMENT_PROCESS,
        },
        {
            name: STEPS.DEFAULT_BIN_PROCESS,
        },
        {
            name: STEPS.EMPLOYEE_MAPPING,
        },
        {
            name: STEPS.ORDER_MAPPING_TO_WMS_STATUS,
        },
        {
            name: STEPS.BIN_CHECK_IN_TRANSACTION,
        },
        {
            name: STEPS.SNBN_MAPPING_TO_SNPSN,
        },
        {
            name: STEPS.SNBN_MOVE_TO_SNM,
        },

        // Step 3
        {
            name: STEPS.BILL_CHECK,
        },
        {
            name: STEPS.ADJUSTMENT_CHECK,
        },
        {
            name: STEPS.PURCHASE_RETURN_CHECK,
        },
        {
            name: STEPS.ORDER_CHECK,
        },
        {
            name: STEPS.ORDER_ITEM_BUNDLE_CHECK,
        },
        {
            name: STEPS.SALES_RETURN_CHECK,
        },
        {
            name: STEPS.TRANSFER_CHECK,
        },
        {
            name: STEPS.ARCHIVE_ITEM_CHECK,
        },
        {
            name: STEPS.SNBN_RACK_CHECK,
        },
        {
            name: STEPS.SNBN_ILLEGAL_CHECK,
        },

        // Step 4
        {
            name: STEPS.DEFAULT_BIN_CHECK,
        },
        {
            name: STEPS.DATA_AFTER_MIGRATION,
        },

        // Step 5
        {
            name: STEPS.TOTAL_SKU_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_PO_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_TRANSFER_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_TRANSFER_TRANSIT_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_TRANSFER_SHIPPED_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_SALES_RETURN_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ONLINE_RETURN_REJECTED_AFTER_MIGRATION
        },
        {
            name: STEPS.TOTAL_ONLINE_RETURN_APPROVED_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_BILL_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_INVOICE_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ADJUSTMENT_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_PURCHASE_RETURN_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_NOT_PAID_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_CANCEL_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_READY_TO_PICK_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_CONFIRM_PICK_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_READY_TO_SHIP_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_SHIPPED_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_COMPLETED_AFTER_MIGRATION,
        },
        {
            name: STEPS.TOTAL_ORDER_FAILED_AFTER_MIGRATION,
        },

        // Step 6
        {
            name: STEPS.BILL_READONLY_CHECK,
        },
        {
            name: STEPS.TRANSFER_READONLY_CHECK,
        },
        {
            name: STEPS.SALES_RETURN_READONLY_CHECK,
        },
        {
            name: STEPS.ENABLE_TRIGGER_CHECK,
        },
        {
            name: STEPS.UPDATE_ACCOUNT_SUPPORT,
        },
        {
            name: STEPS.TURN_ON_INTEGRATION,
        },
        {
            name: STEPS.TURN_ON_SYNC_ORDER,
        },
    ]
}