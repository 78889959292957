import React from 'react';
import { Spinner } from "react-bootstrap";
import LogoS from "../../assets/utils/images/logo-loading.png"

const Index = () => {
  return (
    <div className="loader-container">
        <div className="loader-container-inner">
            <Spinner animation="border"></Spinner> <img src={LogoS} alt="logo" />
        </div>
    </div>
  )
}

export const Spinners = () => {
    return (
        <>
            <Spinner animation="border"></Spinner> <img src={LogoS} alt="logo" />
        </>
    )
}

export default Index;