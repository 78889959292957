import React from 'react';
import { Button, CardBody, CardHeader } from 'reactstrap';
import { useSelector } from 'react-redux';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faAngleLeft,
    faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import { 
    LABEL,
    STEP_PROCESS,
} from '../../../helpers/constants';
import TableBeforeAfter from './TableBeforeAfter';

library.add(
    faAngleLeft,
    faAngleRight,
);

const Block1 = () => {
    const stateMigration = useSelector((state) => state.migration);

    const { stepMigration } = stateMigration;

    return (
        <CardBody>
            <CardHeader>
                <i className="header-icon lnr-license icon-gradient bg-plum-plate"> </i>
                {LABEL.DATA_BEFORE_MIGRATION}
                <div className="btn-actions-pane-right">
                    {(stepMigration?.totalOrderFailedBeforeMigration === 0 || stepMigration?.totalOrderFailedBeforeMigration > 0) ? (
                        <Button color='primary'>{STEP_PROCESS.PASS}</Button>
                    ) : (
                        <Button className="mb-2 me-2" active color="warning">
                            <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                        </Button>
                    )}
                </div>
            </CardHeader>
            {stepMigration.defaultBinCheck !== STEP_PROCESS.FINISHED && (
                <TableBeforeAfter />
            )}
        </CardBody>
    )
}

export default Block1