import React from 'react';
import { Button, CardBody, CardHeader, Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import { 
  STEP_PROCESS,
  LABEL,
} from '../../../helpers/constants';
import { 
  HandleChangeStateRetryMigrate, 
  setStepMigration 
} from '../../../redux/migration/migrateActions';
import { getDataWmsMigration } from '../../../service/migration';

library.add(
    faAngleLeft,
);

const Block3 = () => {
  const dispatch = useDispatch();
  const stateMigration = useSelector((state) => state.migration);

  const { stepMigration, waitProcessLabel, retryMigration, tempPayload, token } = stateMigration;

  const btnRetry = async (stateName) => {
    // Set BtnRetry false
    dispatch(HandleChangeStateRetryMigrate({
      name: stateName,
      value: false,
    }));

    // Service get wms
    await getDataWmsMigration(token)
    .then((response) => {
        const { step_migration } = response.data.data;

        // Set State Step Migration
        dispatch(setStepMigration(tempPayload, step_migration, token));
    }).catch(() => {
        // Just for Retry
        dispatch(HandleChangeStateRetryMigrate({
            name: stateName,
            value: true,
        }));
    });
  }

  return (
    <CardBody>
      <CardHeader>
          <i className="header-icon lnr-license icon-gradient bg-plum-plate"> </i>
          {LABEL.BIN_CHECK_IN_TRANSACTION}
          <div className="btn-actions-pane-right">
              {(stepMigration.snBnIllegalCheck === STEP_PROCESS.FINISHED) ? (
                  <Button color='primary'>{STEP_PROCESS.PASS}</Button>
              ) : (
                  <Button className="mb-2 me-2" active color="warning">
                      <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                  </Button>
              )}
          </div>
      </CardHeader>
      <Table className="mb-0" bordered>
        <thead>
          <tr>
            <th style={{width: "71%"}}>Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">{LABEL.BILL_CHECK}</th>
            <td>
                {stepMigration.billCheck === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.billCheck === STEP_PROCESS.FAILED && retryMigration.billCheck === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('billCheck')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.billCheck ? waitProcessLabel.billCheck : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.ADJUSTMENT_CHECK}</th>
            <td>
                {stepMigration.adjustmentCheck === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.adjustmentCheck === STEP_PROCESS.FAILED && retryMigration.adjustmentCheck === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('adjustmentCheck')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.adjustmentCheck ? waitProcessLabel.adjustmentCheck : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.PURCHASE_RETURN_CHECK}</th>
            <td>
                {stepMigration.purchaseReturnCheck === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.purchaseReturnCheck === STEP_PROCESS.FAILED && retryMigration.purchaseReturnCheck === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('purchaseReturnCheck')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.purchaseReturnCheck ? waitProcessLabel.purchaseReturnCheck : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.ORDER_CHECK}</th>
            <td>
                {stepMigration.orderCheck === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.orderCheck === STEP_PROCESS.FAILED && retryMigration.orderCheck === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('orderCheck')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.orderCheck ? waitProcessLabel.orderCheck : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.ORDER_ITEM_BUNDLE_CHECK}</th>
            <td>
                {stepMigration.orderItemBundleCheck === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.orderItemBundleCheck === STEP_PROCESS.FAILED && retryMigration.orderItemBundleCheck === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('orderItemBundleCheck')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.orderItemBundleCheck ? waitProcessLabel.orderItemBundleCheck : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.SALES_RETURN_CHECK}</th>
            <td>
                {stepMigration.salesReturnCheck === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.salesReturnCheck === STEP_PROCESS.FAILED && retryMigration.salesReturnCheck === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('salesReturnCheck')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.salesReturnCheck ? waitProcessLabel.salesReturnCheck : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.TRANSFER_CHECK}</th>
            <td>
                {stepMigration.transferCheck === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.transferCheck === STEP_PROCESS.FAILED && retryMigration.transferCheck === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('transferCheck')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.transferCheck ? waitProcessLabel.transferCheck : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.ARCHIVE_ITEM_CHECK}</th>
            <td>
                {stepMigration.archiveItemCheck === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.archiveItemCheck === STEP_PROCESS.FAILED && retryMigration.archiveItemCheck === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('archiveItemCheck')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.archiveItemCheck ? waitProcessLabel.archiveItemCheck : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.SNBN_RACK_CHECK}</th>
            <td>
                {stepMigration.snBnRackCheck === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.snBnRackCheck === STEP_PROCESS.FAILED && retryMigration.snBnRackCheck === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('snBnRackCheck')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.snBnRackCheck ? waitProcessLabel.snBnRackCheck : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.SNBN_ILLEGAL_CHECK}</th>
            <td>
                <>
                    <span>{stepMigration.totalSnbnIllegal}</span>
                </>
            </td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
  )
}

export default Block3