import React, { useEffect } from 'react';
import { Button, CardBody, Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import { 
  STEP_PROCESS,
  LABEL,
  STATUS,
} from '../../../helpers/constants';
import { 
  HandleChangeStateRetryMigrate, 
  HandleChangeStateSkipMigrate, 
  setStepMigration,
  HandleChangeStateStepMigrate,
  getDataWms
} from '../../../redux/migration/migrateActions';
import { notifMessage } from '../../../helpers/notif';
import { getDataWmsMigration, updateWmsMigration } from '../../../service/migration';
import { USERNAME } from '../../../service/variable';

library.add(
    faAngleLeft,
);

const Block2 = () => {
  const dispatch = useDispatch();
  const stateMigration = useSelector((state) => state.migration);
  

  useEffect(() => {
    // checking every 10 seconds for wms migration
    if (stepMigration.signedAsWms === STEP_PROCESS.FINISHED) {
      // let idInteval = '';
      if (stepMigration.valWmsMigrationProcess === 0) {
        const idInteval = setInterval(async () => {
          const getMp = await getDataWmsMigration(stateMigration.token);
          const { migration_process, migration_process_message } = getMp.data.data;

          if (migration_process) {
            // Get Data Wms
            let objStepMigration = {
              created_by: USERNAME,
              step_migration: await getDataWms(stateMigration.token)
            }

            if (migration_process === 1) {
              // Set state value valWmsMigrationProcess
              dispatch(HandleChangeStateStepMigrate({
                name: 'valWmsMigrationProcess',
                value: migration_process, 
              }));
              
              // Service update wms migrate
              objStepMigration.step_migration.wms_migration_process = STEP_PROCESS.FINISHED;
              await updateWmsMigration(objStepMigration, stateMigration.token);
  
              // Set state value wmsMigrationProcess
              dispatch(HandleChangeStateStepMigrate({
                  name: 'wmsMigrationProcess',
                  value: STEP_PROCESS.FINISHED, 
              }));
  
              // Notif
              notifMessage(STATUS.SUCCESS, LABEL.WMS_MIGRATION_PROCESS);
  
              await btnRetry('wmsMigrationProcess');
            }

            if (migration_process === 2) {
              // Set state value valWmsMigrationProcess
              dispatch(HandleChangeStateStepMigrate({
                  name: 'valWmsMigrationProcess',
                  value: migration_process, 
              }));

              // Set state value msgWmsMigrationProcess
              dispatch(HandleChangeStateStepMigrate({
                  name: 'msgWmsMigrationProcess',
                  value: migration_process_message, 
              }));

              // Service update wms migrate
              objStepMigration.step_migration.wms_migration_process = STEP_PROCESS.FAILED;
              await updateWmsMigration(objStepMigration, stateMigration.token);

              // Set state value wmsMigrationProcess
              dispatch(HandleChangeStateStepMigrate({
                  name: 'wmsMigrationProcess',
                  value: STEP_PROCESS.FAILED, 
              }));

              // Set State and add retry
              dispatch(HandleChangeStateRetryMigrate({
                  name: 'wmsMigrationProcess',
                  value: true, // Set Value Here
              }));

              // Notif
              notifMessage(STATUS.ERROR, LABEL.WMS_MIGRATION_PROCESS);
            }
          }
        }, 10000); // Set 10 second

        return () => clearInterval(idInteval);
      }
    }
  });

  useEffect(() => {
    
    // checking every 10 seconds for app migration
    if (stepMigration.valMigrateToApp === 0) {
      const theInterval = setInterval(async () => {
        const getMp = await getDataWmsMigration(stateMigration.token);
        const { migration_process_app, migration_process_app_message } = getMp.data.data;

        if (migration_process_app) {
          // Get Data Wms
          let objStepMigration = {
            created_by: USERNAME,
            step_migration: await getDataWms(stateMigration.token)
          }

          if (migration_process_app === 1) {
            // Set state value valMigrateToApp
            dispatch(HandleChangeStateStepMigrate({
              name: 'valMigrateToApp',
              value: migration_process_app, 
            }));
            
            // Service update wms migrate
            objStepMigration.step_migration.migrate_to_app = STEP_PROCESS.FINISHED;
            await updateWmsMigration(objStepMigration, stateMigration.token);

            // Set state value migrateToApp
            dispatch(HandleChangeStateStepMigrate({
                name: 'migrateToApp',
                value: STEP_PROCESS.FINISHED, 
            }));

            // Notif
            notifMessage(STATUS.SUCCESS, LABEL.MIGRATE_TO_APP);

            await btnRetry('migrateToApp');
          }

          if (migration_process_app === 2) {
            // Set state value valMigrateToApp
            dispatch(HandleChangeStateStepMigrate({
                name: 'valMigrateToApp',
                value: migration_process_app, 
            }));

            // Set state value msgMigrateToApp
            dispatch(HandleChangeStateStepMigrate({
                name: 'msgMigrateToApp',
                value: migration_process_app_message, 
            }));

            // Service update wms migrate
            objStepMigration.step_migration.migrate_to_app = STEP_PROCESS.FAILED;
            await updateWmsMigration(objStepMigration, stateMigration.token);

            // Set state value migrateToApp
            dispatch(HandleChangeStateStepMigrate({
                name: 'migrateToApp',
                value: STEP_PROCESS.FAILED, 
            }));

            // Set State and add retry
            dispatch(HandleChangeStateRetryMigrate({
                name: 'migrateToApp',
                value: true, // Set Value Here
            }));

            // Notif
            notifMessage(STATUS.ERROR, LABEL.MIGRATE_TO_APP);
          }
        }
      }, 10000); // Set 10 second

      return () => clearInterval(theInterval);
    }
  });

  const { stepMigration, waitProcessLabel, retryMigration, tempPayload, token } = stateMigration;

  const btnRetry = async (stateName, handle = '') => {
    if (handle === STEP_PROCESS.SKIP) {
      // Set BtnSkip true
      dispatch(HandleChangeStateSkipMigrate({
        name: stateName,
        value: true,
      }));
    } else {
      // Set BtnRetry false
      dispatch(HandleChangeStateRetryMigrate({
          name: stateName,
          value: false,
      }));

      // Set BtnSkip true
      dispatch(HandleChangeStateSkipMigrate({
          name: stateName,
          value: false,
      }));      
    }

    // Service get wms
    await getDataWmsMigration(token)
    .then((response) => {
        const { step_migration } = response.data.data;

        // Set State Step Migration
        dispatch(setStepMigration(tempPayload, step_migration, token));
    }).catch(() => {
        // Just for Retry
        dispatch(HandleChangeStateRetryMigrate({
            name: stateName,
            value: true,
        }));
    });
  }

  const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">{LABEL.WMS_MIGRATION_PROCESS}</Popover.Header>
        <Popover.Body>
          {stepMigration.msgWmsMigrationProcess}
        </Popover.Body>
    </Popover>
  );

  return (
    <CardBody>
      <Table className="mb-0" bordered>
        <thead>
          <tr>
            <th style={{width: "71%"}}>Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">{LABEL.MIGRATE_TO_APP}</th>
            <td>
                {stepMigration.migrateToApp === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.migrateToApp === STEP_PROCESS.FAILED && retryMigration.migrateToApp === true) ? (
                    <>
                      <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                      <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('migrateToApp')}>{STEP_PROCESS.RETRY}</Button>
                    </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.migrateToApp ? waitProcessLabel.migrateToApp : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.TURN_OFF_INTEGRATION}</th>
            <td>
                {stepMigration.turnOffIntegration === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.turnOffIntegration === STEP_PROCESS.FAILED && retryMigration.turnOffIntegration === true) ? (
                    <>
                      <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                      <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('turnOffIntegration')}>{STEP_PROCESS.RETRY}</Button>
                    </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.turnOffIntegration ? waitProcessLabel.turnOffIntegration : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.SIGNED_AS_WMS}</th>
            <td>
                {stepMigration.signedAsWms === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.signedAsWms === STEP_PROCESS.FAILED && retryMigration.signedAsWms === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('signedAsWms')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.signedAsWms ? waitProcessLabel.signedAsWms : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.WMS_MIGRATION_PROCESS}</th>
            <td>
                {stepMigration.wmsMigrationProcess === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.wmsMigrationProcess === STEP_PROCESS.FAILED && retryMigration.wmsMigrationProcess === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('wmsMigrationProcess')}>{STEP_PROCESS.RETRY}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnRetry('wmsMigrationProcess', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                        <Button className="mb-2 me-2" active variant="success">Detail Error</Button>
                    </OverlayTrigger>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.wmsMigrationProcess ? waitProcessLabel.wmsMigrationProcess : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.DEFAULT_BIN_ITEM_MOVEMENT_PROCESS}</th>
            <td>
                {stepMigration.defaultBinItemMovementProcess === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.defaultBinItemMovementProcess === STEP_PROCESS.FAILED && retryMigration.defaultBinItemMovementProcess === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('defaultBinItemMovementProcess')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.defaultBinItemMovementProcess ? waitProcessLabel.defaultBinItemMovementProcess : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.DEFAULT_BIN_PROCESS}</th>
            <td>
                {stepMigration.defaultBinProcess === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.defaultBinProcess === STEP_PROCESS.FAILED && retryMigration.defaultBinProcess === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('defaultBinProcess')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.defaultBinProcess ? waitProcessLabel.defaultBinProcess : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.EMPLOYEE_MAPPING}</th>
            <td>
                {stepMigration.employeeMapping === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.employeeMapping === STEP_PROCESS.FAILED && retryMigration.employeeMapping === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('employeeMapping')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.employeeMapping ? waitProcessLabel.employeeMapping : STEP_PROCESS.WAITING}
                    </Button>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.ORDER_MAPPING_TO_WMS_STATUS}</th>
            <td>
                {stepMigration.orderMappingToWmsStatus === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.orderMappingToWmsStatus === STEP_PROCESS.FAILED && retryMigration.orderMappingToWmsStatus === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('orderMappingToWmsStatus')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                  <>
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.orderMappingToWmsStatus ? waitProcessLabel.orderMappingToWmsStatus : STEP_PROCESS.WAITING}
                    </Button>
                  </>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.SNBN_MAPPING_TO_SNPSN}</th>
            <td>
                {stepMigration.snBnMappingToSnPsn === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.snBnMappingToSnPsn === STEP_PROCESS.FAILED && retryMigration.snBnMappingToSnPsn === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('snBnMappingToSnPsn')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                  <>
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.snBnMappingToSnPsn ? waitProcessLabel.snBnMappingToSnPsn : STEP_PROCESS.WAITING}
                    </Button>
                  </>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">{LABEL.SNBN_MOVE_TO_PSN}</th>
            <td>
                {stepMigration.snBnMovetoSnm === STEP_PROCESS.FINISHED ? (
                  <Button className="mb-2 me-2" active color="info">
                      {STEP_PROCESS.FINISHED}
                  </Button>
                ) : (stepMigration.snBnMovetoSnm === STEP_PROCESS.FAILED && retryMigration.snBnMovetoSnm === true) ? (
                  <>
                    <Button className="mb-2 me-2 btn-hover-shine" active color="alternate">{STEP_PROCESS.FAILED}</Button>
                    <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnRetry('snBnMovetoSnm')}>{STEP_PROCESS.RETRY}</Button>
                  </>
                ) : (
                  <>
                    <Button className="mb-2 me-2" active color="warning">
                        <Spinner animation="border" size="sm"></Spinner>
                        {' '} {waitProcessLabel.snBnMovetoSnm ? waitProcessLabel.snBnMovetoSnm : STEP_PROCESS.WAITING}
                    </Button>
                  </>
                )}
            </td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
  )
}

export default Block2