import { Route, Redirect, useHistory } from "react-router-dom";
import React, {Suspense, lazy, Fragment, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {
    ToastContainer,
} from 'react-toastify';
import ModalForm from "../../Views/Modals";
import { getCookie, removeDataUser } from "../../helpers/cookies";
import { HandleChangeStateProfile } from "../../redux/global/globalActions";
import AppLoader from "../AppLoader";

const Login = lazy(() => import('../../Views/Login'));
const Wms = lazy(() => import('../../Views/Wms'));
const WmsList = lazy(() => import('../../Views/WmsList'));

const auth = getCookie("isLogin", "app_auth_authenticated");

const AppMain = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (auth !== true) {
            history.push("/login");

            // Clear Cookie & LocalStorage
            removeDataUser();
        } else {
            // Set State Profile
            dispatch(HandleChangeStateProfile({
                fullName: getCookie("account", "app_account_fullname"),
                username: getCookie("account", "app_account_username"),
            }));
        }
    }, [history, dispatch]); 

    return (
        <Fragment>

            {/* Dashboards */}

            <Suspense fallback={
                <AppLoader />
            }>
                <Route path="/login" component={Login}/>
                <Route path="/wms/migration" component={Wms}/>
                <Route path="/wms/list" component={WmsList}/>
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/wms/migration"/>
            )}/>
            <ToastContainer/>

            {/* Modal Form */}
            <ModalForm />
        </Fragment>
    )
};

export default AppMain;