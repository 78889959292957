import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = 'tU^x+7&c^+X*LDkLDQeGU5J6w7Bwk?%fUpfstdnG^$wVQ-4Thv';
const IV = 'I8zyA4lVhMCaJ5Kg';

export const _doEncryptObj = (obj) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(obj), ENCRYPTION_KEY, {
        keySize: 128 / 8,
        iv: IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString();

    return encrypted;
};

export const _doDecryptObj = (text) => {
    const decrypted = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(text, ENCRYPTION_KEY, {
        keySize: 128 / 8,
        iv: IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })));

    return decrypted;
};