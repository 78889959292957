import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faAngleLeft,
    faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { Offcanvas, Spinner } from 'react-bootstrap';
import { 
    HandleChangeStateRetryMigrate, 
    HandleChangeStateSkipMigrate, 
    setStepMigration 
} from '../../../redux/migration/migrateActions';
import { getDataWmsMigration } from '../../../service/migration';
import { 
    STEP_PROCESS,
    LABEL
} from '../../../helpers/constants';

library.add(
    faAngleLeft,
    faAngleRight,
);

const TableBeforeAfter = () => {
    const stateMigration = useSelector((state) => state.migration);
    const dispatch = useDispatch();

    const { stepMigration, retryMigration, waitProcessLabel, tempPayload, token } = stateMigration;

    const [show, setShow] = useState(false); 

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const DetailRelasi = () => {
        return (
            <Offcanvas show={show} onHide={handleClose} placement='bottom'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Relasi : {LABEL.TOTAL_ORDER_SHIPPED}	                                
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>
                                    {LABEL.CHECK_SHIPPED_ORDER_APP_WITH_READY_TO_SHIP_APP2} : 
                                    {' '}
                                    {stepMigration?.totalShippedOrderAppWithReadyToShipApp2 !== '' ? (
                                        <>{stepMigration?.totalShippedOrderAppWithReadyToShipApp2}</>
                                    ) : (
                                        <>
                                            <Spinner animation="grow" size="sm"/> {STEP_PROCESS.WAITING}
                                        </>
                                    )}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    {LABEL.CHECK_DUPLICATE_READY_TO_SHIP_WITH_SHIPPED_ORDER} : 
                                    {' '}
                                    {stepMigration?.totalDuplicateReadyToShipWithShippedOrder !== '' ? (
                                        <>{stepMigration?.totalDuplicateReadyToShipWithShippedOrder}</>
                                    ) : (
                                        <>
                                            <Spinner animation="grow" size="sm"/> {STEP_PROCESS.WAITING}
                                        </>
                                    )}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    {LABEL.CHECK_WAREHOUSE_STATUS_READY_TO_SHIP_NOT_SHOW_READY_TO_SHIP} : 
                                    {' '}
                                    {stepMigration?.totalWarehouseStatusReadyToShipNotShowInReadyToShip !== '' ? (
                                        <>{stepMigration?.totalWarehouseStatusReadyToShipNotShowInReadyToShip}</>
                                    ) : (
                                        <>
                                            <Spinner animation="grow" size="sm"/> {STEP_PROCESS.WAITING}
                                        </>
                                    )}
                                </th>
                            </tr>
                        </thead>
                    </Table>
                </Offcanvas.Body>
            </Offcanvas>
        )
    }

    const btnSkipRetry = async (stateName, handle) => {
        if (handle !== STEP_PROCESS.SKIP) {
            // Set BtnRetry false
            dispatch(HandleChangeStateRetryMigrate({
                name: stateName,
                value: false,
            }));

            // Set BtnSkip true
            dispatch(HandleChangeStateSkipMigrate({
                name: stateName,
                value: false,
            }));
        } else {
            // Set BtnSkip true
            dispatch(HandleChangeStateSkipMigrate({
                name: stateName,
                value: true,
            }));
        }

        // Service get wms
        await getDataWmsMigration(token)
        .then((response) => {
            const { step_migration } = response.data.data;

            // Set State Step Migration
            dispatch(setStepMigration(tempPayload, step_migration, token));
        }).catch(() => {
            // Just for Retry
            if (handle !== STEP_PROCESS.SKIP) {
                dispatch(HandleChangeStateRetryMigrate({
                    name: stateName,
                    value: true,
                }));
            }   
        });
    }

    return (
        <Table className="mb-0" bordered>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Before</th>
                    <th>After</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">{LABEL.TOTAL_SKU}</th>
                    <td>
                        {(stepMigration?.totalSkuBeforeMigration === 0 || stepMigration?.totalSkuBeforeMigration > 0) ? stepMigration?.totalSkuBeforeMigration 
                        : retryMigration.totalSkuBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalSkuBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalSkuBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalSkuBeforeMigration ? waitProcessLabel.totalSkuBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalSkuAfterMigration === 0 || stepMigration?.totalSkuAfterMigration > 0) ? stepMigration?.totalSkuAfterMigration 
                        : retryMigration.totalSkuAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalSkuAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalSkuAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalSkuAfterMigration ? waitProcessLabel.totalSkuAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalSkuBeforeMigration !== '' && stepMigration?.totalSkuAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalSkuBeforeMigration === stepMigration?.totalSkuAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_PO}</th>
                    <td>
                        {(stepMigration?.totalPoBeforeMigration === 0 || stepMigration?.totalPoBeforeMigration > 0) ? stepMigration?.totalPoBeforeMigration 
                        : retryMigration.totalPoBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalPoBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalPoBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalPoBeforeMigration ? waitProcessLabel.totalPoBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalPoAfterMigration === 0 || stepMigration?.totalPoAfterMigration > 0) ? stepMigration?.totalPoAfterMigration 
                        : retryMigration.totalPoAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalPoAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalPoAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalPoAfterMigration ? waitProcessLabel.totalPoAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalPoBeforeMigration !== '' && stepMigration?.totalPoAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalPoBeforeMigration === stepMigration?.totalPoAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_TRANSFER}</th>
                    <td>
                        {(stepMigration?.totalTransferBeforeMigration === 0 || stepMigration?.totalTransferBeforeMigration > 0) ? stepMigration?.totalTransferBeforeMigration 
                        : retryMigration.totalTransferBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalTransferBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalTransferBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalTransferBeforeMigration ? waitProcessLabel.totalTransferBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalTransferAfterMigration === 0 || stepMigration?.totalTransferAfterMigration > 0) ? stepMigration?.totalTransferAfterMigration 
                        : retryMigration.totalTransferAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalTransferAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalTransferAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalTransferAfterMigration ? waitProcessLabel.totalTransferAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalTransferBeforeMigration !== '' && stepMigration?.totalTransferAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalTransferBeforeMigration === stepMigration?.totalTransferAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_TRANSFER_TRANSIT}</th>
                    <td>
                        {(stepMigration?.totalTransferTransitBeforeMigration === 0 || stepMigration?.totalTransferTransitBeforeMigration > 0) ? stepMigration?.totalTransferTransitBeforeMigration 
                        : retryMigration.totalTransferTransitBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalTransferTransitBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalTransferTransitBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalTransferTransitBeforeMigration ? waitProcessLabel.totalTransferTransitBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalTransferTransitAfterMigration === 0 || stepMigration?.totalTransferTransitAfterMigration > 0) ? stepMigration?.totalTransferTransitAfterMigration 
                        : retryMigration.totalTransferTransitAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalTransferTransitAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalTransferTransitAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalTransferTransitAfterMigration ? waitProcessLabel.totalTransferTransitAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalTransferTransitBeforeMigration !== '' && stepMigration?.totalTransferTransitAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalTransferTransitBeforeMigration === stepMigration?.totalTransferTransitAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_TRANSFER_SHIPPED}</th>
                    <td>
                        {(stepMigration?.totalTransferShippedBeforeMigration === 0 || stepMigration?.totalTransferShippedBeforeMigration > 0) ? stepMigration?.totalTransferShippedBeforeMigration 
                        : retryMigration.totalTransferShippedBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalTransferShippedBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalTransferShippedBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalTransferShippedBeforeMigration ? waitProcessLabel.totalTransferShippedBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalTransferShippedAfterMigration === 0 || stepMigration?.totalTransferShippedAfterMigration > 0) ? stepMigration?.totalTransferShippedAfterMigration 
                        : retryMigration.totalTransferShippedAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalTransferShippedAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalTransferShippedAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalTransferShippedAfterMigration ? waitProcessLabel.totalTransferShippedAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalTransferShippedBeforeMigration !== '' && stepMigration?.totalTransferShippedAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalTransferShippedBeforeMigration === stepMigration?.totalTransferShippedAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_SALES_RETURN}</th>
                    <td>
                        {(stepMigration?.totalSalesReturnBeforeMigration === 0 || stepMigration?.totalSalesReturnBeforeMigration > 0) ? stepMigration?.totalSalesReturnBeforeMigration 
                        : retryMigration.totalSalesReturnBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalSalesReturnBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalSalesReturnBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalSalesReturnBeforeMigration ? waitProcessLabel.totalSalesReturnBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalSalesReturnAfterMigration === 0 || stepMigration?.totalSalesReturnAfterMigration > 0) ? stepMigration?.totalSalesReturnAfterMigration 
                        : retryMigration.totalSalesReturnAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalSalesReturnAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalSalesReturnAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalSalesReturnAfterMigration ? waitProcessLabel.totalSalesReturnAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalSalesReturnBeforeMigration !== '' && stepMigration?.totalSalesReturnAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalSalesReturnBeforeMigration === stepMigration?.totalSalesReturnAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_ONLINE_RETURN_REJECTED}</th>
                    <td>
                        {(stepMigration?.totalOnlineReturnRejectedBeforeMigration === 0 || stepMigration?.totalOnlineReturnRejectedBeforeMigration > 0) ? stepMigration?.totalOnlineReturnRejectedBeforeMigration 
                        : retryMigration.totalOnlineReturnRejectedBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOnlineReturnRejectedBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOnlineReturnRejectedBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOnlineReturnRejectedBeforeMigration ? waitProcessLabel.totalOnlineReturnRejectedBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalOnlineReturnRejectedAfterMigration === 0 || stepMigration?.totalOnlineReturnRejectedAfterMigration >  0) ? stepMigration?.totalOnlineReturnRejectedAfterMigration 
                        : retryMigration.totalOnlineReturnRejectedAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOnlineReturnRejectedAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOnlineReturnRejectedAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOnlineReturnRejectedAfterMigration ? waitProcessLabel.totalOnlineReturnRejectedAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalOnlineReturnRejectedBeforeMigration !== '' && stepMigration?.totalOnlineReturnRejectedAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalOnlineReturnRejectedBeforeMigration === stepMigration?.totalOnlineReturnRejectedAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_ONLINE_RETURN_APPROVED}</th>
                    <td>
                        {(stepMigration?.totalOnlineReturnApprovedBeforeMigration === 0 || stepMigration?.totalOnlineReturnApprovedBeforeMigration > 0) ? stepMigration?.totalOnlineReturnApprovedBeforeMigration 
                        : retryMigration.totalOnlineReturnApprovedBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOnlineReturnApprovedBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOnlineReturnApprovedBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOnlineReturnApprovedBeforeMigration ? waitProcessLabel.totalOnlineReturnApprovedBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalOnlineReturnApprovedAfterMigration === 0 || stepMigration?.totalOnlineReturnApprovedAfterMigration > 0) ? stepMigration?.totalOnlineReturnApprovedAfterMigration 
                        : retryMigration.totalOnlineReturnApprovedAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOnlineReturnApprovedAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOnlineReturnApprovedAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOnlineReturnApprovedAfterMigration ? waitProcessLabel.totalOnlineReturnApprovedAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalOnlineReturnApprovedBeforeMigration !== '' && stepMigration?.totalOnlineReturnApprovedAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalOnlineReturnApprovedBeforeMigration === stepMigration?.totalOnlineReturnApprovedAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_BILL}</th>
                    <td>
                        {(stepMigration?.totalBillBeforeMigration === 0 || stepMigration?.totalBillBeforeMigration > 0) ? stepMigration?.totalBillBeforeMigration 
                        : retryMigration.totalBillBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalBillBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalBillBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalBillBeforeMigration ? waitProcessLabel.totalBillBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalBillAfterMigration === 0 || stepMigration?.totalBillAfterMigration > 0) ? stepMigration?.totalBillAfterMigration 
                        : retryMigration.totalBillAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalBillAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalBillAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalBillAfterMigration ? waitProcessLabel.totalBillAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalBillBeforeMigration !== '' && stepMigration?.totalBillAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalBillBeforeMigration === stepMigration?.totalBillAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_INVOICE}</th>
                    <td>
                        {(stepMigration?.totalInvoiceBeforeMigration === 0 || stepMigration?.totalInvoiceBeforeMigration > 0) ? stepMigration?.totalInvoiceBeforeMigration 
                        : retryMigration.totalInvoiceBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalInvoiceBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalInvoiceBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalInvoiceBeforeMigration ? waitProcessLabel.totalInvoiceBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalInvoiceAfterMigration === 0 || stepMigration?.totalInvoiceAfterMigration > 0) ? stepMigration?.totalInvoiceAfterMigration 
                        : retryMigration.totalInvoiceAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalInvoiceAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalInvoiceAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalInvoiceAfterMigration ? waitProcessLabel.totalInvoiceAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalInvoiceBeforeMigration !== '' && stepMigration?.totalInvoiceAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalInvoiceBeforeMigration === stepMigration?.totalInvoiceAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_ADJUSTMENT}</th>
                    <td>
                        {(stepMigration?.totalAdjustmentBeforeMigration === 0 || stepMigration?.totalAdjustmentBeforeMigration > 0) ? stepMigration?.totalAdjustmentBeforeMigration 
                        : retryMigration.totalAdjustmentBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalAdjustmentBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalAdjustmentBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalAdjustmentBeforeMigration ? waitProcessLabel.totalAdjustmentBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalAdjustmentAfterMigration === 0 || stepMigration?.totalAdjustmentAfterMigration > 0) ? stepMigration?.totalAdjustmentAfterMigration 
                        : retryMigration.totalAdjustmentAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalAdjustmentAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalAdjustmentAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalAdjustmentAfterMigration ? waitProcessLabel.totalAdjustmentAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalAdjustmentBeforeMigration !== '' && stepMigration?.totalAdjustmentAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalAdjustmentBeforeMigration === stepMigration?.totalAdjustmentAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_ORDER_NOT_PAID}</th>
                    <td>
                        {(stepMigration?.totalOrderNotPaidBeforeMigration === 0 || stepMigration?.totalOrderNotPaidBeforeMigration > 0) ? stepMigration?.totalOrderNotPaidBeforeMigration 
                        : retryMigration.totalOrderNotPaidBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderNotPaidBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderNotPaidBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : ( 
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderNotPaidBeforeMigration ? waitProcessLabel.totalOrderNotPaidBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalOrderNotPaidAfterMigration === 0 || stepMigration?.totalOrderNotPaidAfterMigration > 0) ? stepMigration?.totalOrderNotPaidAfterMigration 
                        : retryMigration.totalOrderNotPaidAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderNotPaidAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderNotPaidAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderNotPaidAfterMigration ? waitProcessLabel.totalOrderNotPaidAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalOrderNotPaidBeforeMigration !== '' && stepMigration?.totalOrderNotPaidAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalOrderNotPaidBeforeMigration === stepMigration?.totalOrderNotPaidAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_ORDER_CANCEL}</th>
                    <td>
                        {(stepMigration?.totalOrderCancelBeforeMigration === 0 || stepMigration?.totalOrderCancelBeforeMigration > 0) ? stepMigration?.totalOrderCancelBeforeMigration 
                        : retryMigration.totalOrderCancelBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderCancelBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderCancelBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderCancelBeforeMigration ? waitProcessLabel.totalOrderCancelBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalOrderCancelAfterMigration === 0 || stepMigration?.totalOrderCancelAfterMigration > 0) ? stepMigration?.totalOrderCancelAfterMigration 
                        : retryMigration.totalOrderCancelAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderCancelAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderCancelAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderCancelAfterMigration ? waitProcessLabel.totalOrderCancelAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalOrderCancelBeforeMigration !== '' && stepMigration?.totalOrderCancelAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalOrderCancelBeforeMigration === stepMigration?.totalOrderCancelAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_ORDER_READY_TO_PICK}</th>
                    <td>
                        {(stepMigration?.totalOrderReadyToPickBeforeMigration === 0 || stepMigration?.totalOrderReadyToPickBeforeMigration > 0) ? stepMigration?.totalOrderReadyToPickBeforeMigration 
                        : retryMigration.totalOrderReadyToPickBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderReadyToPickBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderReadyToPickBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderReadyToPickBeforeMigration ? waitProcessLabel.totalOrderReadyToPickBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalOrderReadyToPickAfterMigration === 0 || stepMigration?.totalOrderReadyToPickAfterMigration > 0) ? stepMigration?.totalOrderReadyToPickAfterMigration 
                        : retryMigration.totalOrderReadyToPickAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderReadyToPickAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderReadyToPickAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderReadyToPickAfterMigration ? waitProcessLabel.totalOrderReadyToPickAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalOrderReadyToPickBeforeMigration !== '' && stepMigration?.totalOrderReadyToPickAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalOrderReadyToPickBeforeMigration === stepMigration?.totalOrderReadyToPickAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_ORDER_CONFIRM_PICK}</th>
                    <td>
                        {(stepMigration?.totalOrderConfirmPickBeforeMigration === 0 || stepMigration?.totalOrderConfirmPickBeforeMigration > 0) ? stepMigration?.totalOrderConfirmPickBeforeMigration 
                        : retryMigration.totalOrderConfirmPickBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderConfirmPickBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderConfirmPickBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderConfirmPickBeforeMigration ? waitProcessLabel.totalOrderConfirmPickBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalOrderConfirmPickAfterMigration === 0 || stepMigration?.totalOrderConfirmPickAfterMigration > 0) ? stepMigration?.totalOrderConfirmPickAfterMigration 
                        : retryMigration.totalOrderConfirmPickAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderConfirmPickAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderConfirmPickAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderConfirmPickAfterMigration ? waitProcessLabel.totalOrderConfirmPickAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalOrderConfirmPickBeforeMigration !== '' && stepMigration?.totalOrderConfirmPickAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalOrderConfirmPickBeforeMigration === stepMigration?.totalOrderConfirmPickAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_ORDER_READY_TO_SHIP}</th>
                    <td>
                        {(stepMigration?.totalOrderReadyToShipBeforeMigration === 0 || stepMigration?.totalOrderReadyToShipBeforeMigration > 0) ? stepMigration?.totalOrderReadyToShipBeforeMigration 
                        : retryMigration.totalOrderReadyToShipBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderReadyToShipBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderReadyToShipBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderReadyToShipBeforeMigration ? waitProcessLabel.totalOrderReadyToShipBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalOrderReadyToShipAfterMigration === 0 || stepMigration?.totalOrderReadyToShipAfterMigration > 0) ? stepMigration?.totalOrderReadyToShipAfterMigration 
                        : retryMigration.totalOrderReadyToShipAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderReadyToShipAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderReadyToShipAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderReadyToShipAfterMigration ? waitProcessLabel.totalOrderReadyToShipAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalOrderReadyToShipBeforeMigration !== '' && stepMigration?.totalOrderReadyToShipAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalOrderReadyToShipBeforeMigration === stepMigration?.totalOrderReadyToShipAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_ORDER_SHIPPED}</th>
                    <td>
                        {(stepMigration?.totalOrderShippedBeforeMigration === 0 || stepMigration?.totalOrderShippedBeforeMigration > 0) ? stepMigration?.totalOrderShippedBeforeMigration 
                        : retryMigration.totalOrderShippedBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderShippedBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderShippedBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderShippedBeforeMigration ? waitProcessLabel.totalOrderShippedBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalOrderShippedAfterMigration === 0 || stepMigration?.totalOrderShippedAfterMigration > 0) ? stepMigration?.totalOrderShippedAfterMigration 
                        : retryMigration.totalOrderShippedAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderShippedAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderShippedAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderShippedAfterMigration ? waitProcessLabel.totalOrderShippedAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalOrderShippedBeforeMigration !== '' && stepMigration?.totalOrderShippedAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalOrderShippedBeforeMigration === (
                                    stepMigration?.totalOrderShippedAfterMigration || 
                                    stepMigration?.totalShippedOrderAppWithReadyToShipApp2 ||
                                    stepMigration?.totalDuplicateReadyToShipWithShippedOrder ||
                                    stepMigration?.totalWarehouseStatusReadyToShipNotShowInReadyToShip
                                ) ? (
                                    <>
                                        <Button className="mb-2 me-2" active color="info">
                                            {STEP_PROCESS.FINISHED}
                                        </Button>
                                        <Button className="mb-2 me-2" active color="dark" onClick={handleShow}>
                                            Detail
                                        </Button>
                                    </>   
                                ) : (
                                    <>
                                        <Button className="mb-2 me-2" active color="alternate">
                                            {STEP_PROCESS.FAILED}
                                        </Button>
                                        <Button className="mb-2 me-2" active color="dark" onClick={handleShow}>
                                            Detail
                                        </Button>
                                    </>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                        <DetailRelasi />
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_ORDER_COMPLETED}</th>
                    <td>
                        {(stepMigration?.totalOrderCompletedBeforeMigration === 0 || stepMigration?.totalOrderCompletedBeforeMigration > 0) ? stepMigration?.totalOrderCompletedBeforeMigration 
                        : retryMigration.totalOrderCompletedBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderCompletedBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderCompletedBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderCompletedBeforeMigration ? waitProcessLabel.totalOrderCompletedBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalOrderCompletedAfterMigration === 0 || stepMigration?.totalOrderCompletedAfterMigration > 0) ? stepMigration?.totalOrderCompletedAfterMigration 
                        : retryMigration.totalOrderCompletedAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderCompletedAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderCompletedAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderShippedAfterMigration ? waitProcessLabel.totalOrderShippedAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalOrderCompletedBeforeMigration !== '' && stepMigration?.totalOrderCompletedAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalOrderCompletedBeforeMigration === stepMigration?.totalOrderCompletedAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{LABEL.TOTAL_ORDER_FAILED}</th>
                    <td>
                        {(stepMigration?.totalOrderFailedBeforeMigration === 0 || stepMigration?.totalOrderFailedBeforeMigration > 0) ? stepMigration?.totalOrderFailedBeforeMigration 
                        : retryMigration.totalOrderFailedBeforeMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderFailedBeforeMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderFailedBeforeMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderFailedBeforeMigration ? waitProcessLabel.totalOrderFailedBeforeMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {(stepMigration?.totalOrderFailedAfterMigration === 0 || stepMigration?.totalOrderFailedAfterMigration > 0) ? stepMigration?.totalOrderFailedAfterMigration 
                        : retryMigration.totalOrderFailedAfterMigration === true ? (
                            <>
                                <Button className="mb-2 me-2 btn-hover-shine" color="secondary" onClick={() => btnSkipRetry('totalOrderFailedAfterMigration')}>{STEP_PROCESS.RETRY}</Button>
                                <Button className="mb-2 me-2 btn-hover-shine" color="danger" onClick={() => btnSkipRetry('totalOrderFailedAfterMigration', STEP_PROCESS.SKIP)}>{STEP_PROCESS.SKIP}</Button>
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner>
                                {' '} {waitProcessLabel.totalOrderFailedAfterMigration ? waitProcessLabel.totalOrderFailedAfterMigration : STEP_PROCESS.WAITING}
                            </Button>
                        )}
                    </td>
                    <td>
                        {stepMigration?.totalOrderFailedBeforeMigration !== '' && stepMigration?.totalOrderFailedAfterMigration !== '' ? (
                            <>
                                {stepMigration?.totalOrderFailedBeforeMigration === stepMigration?.totalOrderFailedAfterMigration ? (
                                    <Button className="mb-2 me-2" active color="info">
                                        {STEP_PROCESS.FINISHED}
                                    </Button>
                                ) : (
                                    <Button className="mb-2 me-2" active color="alternate">
                                        {STEP_PROCESS.FAILED}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button className="mb-2 me-2" active color="warning">
                                <Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}
                            </Button>
                        )}
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}

export default TableBeforeAfter