import { MigrateReducers } from './migrateReducers';
import { STEP_PROCESS, LIMIT_EXEC, LIMIT_EXEC_IM } from '../../helpers/constants';
import { notifMessage } from '../../helpers/notif';
import { USERNAME } from '../../service/variable';
import { 
    getDataWmsMigration,
    updateWmsMigration,
    updateWmsMigrationMp,
    updateFlagWmsMigration,
    disableEnableMigrateToAppProcess,
    appMigrationProcess,
    wmsMigrationProcess,
    disableEnableDefaultBinProcess,
    countDefaultBinProcess,
    defaultBinItemMovementProcess,
    defaultBinProcess,
    employeeMapping,
    disableEnableOrderMapping,
    countOrderMapping,
    orderMapping,

    // Bin Check in Transaction
    checkItemMovementBillDetail,
    checkItemMovementItemAdjDetail,
    checkItemMovementPurchRetDetail,
    checkItemMovementInvoiceDetail,
    checkItemMovementInvoiceDetailBundle,
    checkItemMovementSalesRetDetail,
    checkItemMovementItemTransferDetail,
    checkItemMovementArchive,
    defaultBinCheck,
    checkSnBnRack,
    checkIllegalSnBn,

    updateAccountSupport,
    turnOnIntegration,
    turnOnSyncOrder,
    updateAppMigrationProcess,
    updateWmsMigrationProcess,
    disableEnableDefaultBinItemMovementProcess,
    countDefaultBinItemMovementProcess,

    // handle SN/BN
    disableEnableSnmMapping,
    countSnBnMapping,
    snBnMapping,
    countSnBnMovement,
    snBnMovement,
    disableEnableOrderMovement
} from '../../service/migration';
import { 
    totalSku,
    totalPo,
    totalTransfer, 
    totalTransferTransit, 
    totalTransferShipped, 
    totalTransferShipped2,
    totalSalesReturn, 
    totalOnlineReturnRejected, 
    totalOnlineReturnApproved, 
    totalOnlineReturnApproved2, 
    totalBill, 
    totalInvoice, 
    totalAdjustment, 
    totalOrderNotPaid, 
    totalOrderCancel, 
    totalOrderReady, 
    totalOrderConfirmPick, 
    totalOrderReadyShip, 
    totalOrderShipped, 
    totalOrderShipped2, 

    // Start Check Compare Data Order Shipped
    totalShippedOrderAppWithReadyToShipApp2,
    totalDuplicateReadyToShipWithShippedOrder,
    totalWarehouseStatusReadyToShipNotShowInReadyToShip,
    // End Check Compare Data Order Shipped

    totalOrderCompleted, 
    totalOrderFailed, 
    mPIntegration, 
    turnOffMp, 

    // Readonly Check
    totalTransferReadonlyCheck,
    totalSalesReturnReadonlyCheck,
    getEnableTriggerCheck,
    totalBillReadonlyCheck,
} from './migrateServices';
import {
    STEPS,
    STATUS,
    LABEL,
} from '../../helpers/constants';
import { 
    instanceWmsApp,
    instanceWmsApp2,
} from '../../service/instance';
import { HandleChangeStateGlobal } from '../../redux/global/globalActions';

export const {
    HandleChangeStateMigrate,
    HandleChangeConfirm,
    HandleChangeStateStepMigrate,
    HandleChangeStateWaitProcessLabel,
    HandleChangeStateRetryMigrate,
    HandleChangeStateSkipMigrate,
    HandleUpdateStepMigration,
    HandleClearStepAndRetryMigration,
    HandleResetStateMigration
} = MigrateReducers.actions;

export const getDataWms = async (token) => {
    const result = await getDataWmsMigration(token);

    return result.data.data.step_migration;
}

export const executeWMS = (obj) => async (dispatch, getState) => {
    const { token } = getState().migration;

    let getMp = await getDataWmsMigration(token);
    const { step_migration, marketplace } = getMp.data.data;
    let stepMigration = step_migration;
    let marketPlace = marketplace;

    let objStepMigration = {
        created_by: USERNAME,
        step_migration: {},
    };

    if (obj.name === STEPS.TURN_ON_INTEGRATION) {
        if (stepMigration.turn_on_integration === '' || stepMigration.turn_on_integration === STEP_PROCESS.FAILED) {
            // State Processing
            dispatch(HandleChangeStateStepMigrate({
                name: 'turnOnIntegration',
                value: STEP_PROCESS.WAITING,
            }));

            if (marketPlace.length) {
                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    let filterActive = marketPlace.filter((obj) => obj.is_active === true);
                    // Update MP
                    filterActive.map(async (val) => {
                        await turnOnIntegration({ storeId: val.store_id}, token)
                    });
                    
                    // Service update wms migrate
                    objStepMigration.step_migration.turn_on_integration = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);

                    // State Finish
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'turnOnIntegration',
                        value: STEP_PROCESS.FINISHED,
                    }));

                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.TURN_ON_INTEGRATION);
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.turn_on_integration = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // State Failed
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'turnOnIntegration',
                        value: STEP_PROCESS.FAILED,
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'turnOnIntegration',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.TURN_ON_INTEGRATION);
                }
            } else {                
                // Service update wms migrate
                objStepMigration.step_migration.turn_on_integration = STEP_PROCESS.FAILED;
                await updateWmsMigration(objStepMigration, token);
                
                dispatch(HandleChangeStateStepMigrate({
                    name: 'turnOnIntegration',
                    value: STEP_PROCESS.FINISHED,
                }));

                // Set State and add retry
                dispatch(HandleChangeStateRetryMigrate({
                    name: 'turnOnIntegration',
                    value: true, // Set Value Here
                }));

                // Notif
                notifMessage(STATUS.ERROR, LABEL.TURN_ON_INTEGRATION);
            }
        }
    }

    if (obj.name === STEPS.TURN_ON_SYNC_ORDER) {
        if (stepMigration.turn_on_sync_order === '' || stepMigration.turn_on_sync_order === STEP_PROCESS.FAILED) {
            // State Processing
            dispatch(HandleChangeStateStepMigrate({
                name: 'turnOnSyncOrder',
                value: STEP_PROCESS.WAITING,
            }));
            
            if (marketPlace.length) {
                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    let filterActive = marketPlace.filter((obj) => obj.pause_sales_download === false);

                    // Update MP
                    filterActive.map(async (val) => {
                        await turnOnSyncOrder({ storeId: val.store_id}, token)
                    });

                    // Service update wms migrate
                    objStepMigration.step_migration.turn_on_sync_order = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);

                    // State Finish
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'turnOnSyncOrder',
                        value: STEP_PROCESS.FINISHED,
                    }));

                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.TURN_ON_SYNC_ORDER);
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.turn_on_sync_order = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // State Failed
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'turnOnSyncOrder',
                        value: STEP_PROCESS.FAILED,
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'turnOnSyncOrder',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.TURN_ON_SYNC_ORDER);
                }
            } else {                
                // Service update wms migrate
                objStepMigration.step_migration.turn_on_sync_order = STEP_PROCESS.FAILED;
                await updateWmsMigration(objStepMigration, token);
                
                dispatch(HandleChangeStateStepMigrate({
                    name: 'turnOnSyncOrder',
                    value: STEP_PROCESS.FINISHED,
                }));

                // Set State and add retry
                dispatch(HandleChangeStateRetryMigrate({
                    name: 'turnOnSyncOrder',
                    value: true, // Set Value Here
                }));

                // Notif
                notifMessage(STATUS.ERROR, LABEL.TURN_ON_SYNC_ORDER);
            }
        }
    }
}

export const setStepMigration = (tempPayload, stepMigration, token, resync = true) => async (dispatch, getState) => {
    const { skipMigration } = getState().migration;

    let objStepMigration = {
        created_by: USERNAME,
        step_migration: {}
    };

    for (let i = 0; i < tempPayload.length; i++) {
        const obj = tempPayload[i];
        
        if (obj.name === STEPS.TOTAL_SKU_BEFORE_MIGRATION) {
            if (stepMigration.total_sku_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalSkuBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalSkuBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_SKU}`);
    
                    continue;
                } else {
                    let data = await totalSku(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_sku_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalSkuBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalSkuBeforeMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_SKU);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalSkuBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_SKU);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalSkuBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_SKU);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_PO_BEFORE_MIGRATION) {
            if (stepMigration.total_po_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalPoBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalPoBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_PO}`);
    
                    continue;
                } else {
                    let data = await totalPo(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_po_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalPoBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalPoBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_PO);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalPoBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_PO);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalPoBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_PO);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_TRANSFER_BEFORE_MIGRATION) {
            if (stepMigration.total_transfer_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalTransferBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalTransferBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_TRANSFER}`);
    
                    continue;
                } else {
                    let data = await totalTransfer(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {

                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_transfer_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalTransferBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalTransferBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_TRANSFER);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_TRANSFER_TRANSIT_BEFORE_MIGRATION) {
            if (stepMigration.total_transfer_transit_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalTransferTransitBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalTransferTransitBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_TRANSFER_TRANSIT}`);
    
                    continue;
                } else {
                    let data = await totalTransferTransit(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_transfer_transit_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalTransferTransitBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalTransferTransitBeforeMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_TRANSFER_TRANSIT);
                        
                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferTransitBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER_TRANSIT);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferTransitBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER_TRANSIT);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_TRANSFER_SHIPPED_BEFORE_MIGRATION) {
            if (stepMigration.total_transfer_shipped_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalTransferShippedBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalTransferShippedBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_TRANSFER_SHIPPED}`);
    
                    continue;
                } else {
                    let data = await totalTransferShipped(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_transfer_shipped_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalTransferShippedBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalTransferShippedBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_TRANSFER_SHIPPED);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferShippedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER_SHIPPED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferShippedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER_SHIPPED);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_SALES_RETURN_BEFORE_MIGRATION) {
            if (stepMigration.total_sales_return_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalSalesReturnBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalSalesReturnBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_SALES_RETURN}`);
    
                    continue;
                } else {
                    let data = await totalSalesReturn(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_sales_return_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalSalesReturnBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalSalesReturnBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_SALES_RETURN);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalSalesReturnBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_SALES_RETURN);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalSalesReturnBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_SALES_RETURN);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_ONLINE_RETURN_REJECTED_BEFORE_MIGRATION) {
            if (stepMigration.total_online_return_rejected_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOnlineReturnRejectedBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOnlineReturnRejectedBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ONLINE_RETURN_REJECTED}`);
    
                    continue;
                } else {
                    let data = await totalOnlineReturnRejected(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_online_return_rejected_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalOnlineReturnRejectedBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOnlineReturnRejectedBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ONLINE_RETURN_REJECTED);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOnlineReturnRejectedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ONLINE_RETURN_REJECTED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOnlineReturnRejectedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ONLINE_RETURN_REJECTED);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_ONLINE_RETURN_APPROVED_BEFORE_MIGRATION) {
            if (stepMigration.total_online_return_approved_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOnlineReturnApprovedBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOnlineReturnApprovedBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ONLINE_RETURN_APPROVED}`);
    
                    continue;
                } else {
                    let data = await totalOnlineReturnApproved(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_online_return_approved_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalOnlineReturnApprovedBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOnlineReturnApprovedBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ONLINE_RETURN_APPROVED);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOnlineReturnApprovedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ONLINE_RETURN_APPROVED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOnlineReturnApprovedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ONLINE_RETURN_APPROVED);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_BILL_BEFORE_MIGRATION) {
            if (stepMigration.total_bill_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalBillBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalBillBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_BILL}`);
    
                    continue;
                } else {
                    let data = await totalBill(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_bill_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalBillBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalBillBeforeMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_BILL);
                        
                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalBillBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_BILL);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalBillBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_BILL);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_INVOICE_BEFORE_MIGRATION) {
            if (stepMigration.total_invoice_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalInvoiceBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalInvoiceBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_INVOICE}`);
    
                    continue;
                } else {
                    let data = await totalInvoice(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_invoice_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalInvoiceBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalInvoiceBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_INVOICE);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalInvoiceBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_INVOICE);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalInvoiceBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_INVOICE);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_ADJUSTMENT_BEFORE_MIGRATION) {
            if (stepMigration.total_adjustment_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalAdjustmentBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalAdjustmentBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ADJUSTMENT}`);
    
                    continue;
                } else {
                    let data = await totalAdjustment(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_adjustment_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalAdjustmentBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalAdjustmentBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ADJUSTMENT);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalAdjustmentBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ADJUSTMENT);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalAdjustmentBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ADJUSTMENT);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_ORDER_NOT_PAID_BEFORE_MIGRATION) {
            if (stepMigration.total_order_not_paid_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderNotPaidBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderNotPaidBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_NOT_PAID}`);
    
                    continue;
                } else {
                    let data = await totalOrderNotPaid(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_not_paid_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalOrderNotPaidBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderNotPaidBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_NOT_PAID);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderNotPaidBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_NOT_PAID);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderNotPaidBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_NOT_PAID);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_ORDER_CANCEL_BEFORE_MIGRATION) {
            if (stepMigration.total_order_cancel_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderCancelBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderCancelBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_CANCEL}`);
    
                    continue;
                } else {
                    let data = await totalOrderCancel(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_cancel_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalOrderCancelBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderCancelBeforeMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_CANCEL);
                        
                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderCancelBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_CANCEL);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderCancelBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_CANCEL);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_ORDER_READY_TO_PICK_BEFORE_MIGRATION) {
            if (stepMigration.total_order_ready_to_pick_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderReadyToPickBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderReadyToPickBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_READY_TO_PICK}`);
    
                    continue;
                } else {
                    let data = await totalOrderReady(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_ready_to_pick_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalOrderReadyToPickBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderReadyToPickBeforeMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_READY_TO_PICK);
                        
                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderReadyToPickBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_READY_TO_PICK);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderReadyToPickBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_READY_TO_PICK);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_ORDER_CONFIRM_PICK_BEFORE_MIGRATION) {
            if (stepMigration.total_order_confirm_pick_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderConfirmPickBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderConfirmPickBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_CONFIRM_PICK}`);
    
                    continue;
                } else {
                    let data = await totalOrderConfirmPick(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_confirm_pick_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalOrderConfirmPickBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderConfirmPickBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_CONFIRM_PICK);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderConfirmPickBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_CONFIRM_PICK);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderConfirmPickBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_CONFIRM_PICK);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_ORDER_READY_TO_SHIP_BEFORE_MIGRATION) {
            if (stepMigration.total_order_ready_to_ship_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderReadyToShipBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderReadyToShipBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_READY_TO_SHIP}`);

                    continue;
                } else {
                    let data = await totalOrderReadyShip(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_ready_to_ship_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalOrderReadyToShipBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderReadyToShipBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_READY_TO_SHIP);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderReadyToShipBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_READY_TO_SHIP);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderReadyToShipBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_READY_TO_SHIP);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_ORDER_SHIPPED_BEFORE_MIGRATION) {
            if (stepMigration.total_order_shipped_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderShippedBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderShippedBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_SHIPPED}`);

                    continue;
                } else {
                    let data = await totalOrderShipped(token, instanceWmsApp);
                    let data2 = await totalOrderShipped2(token, instanceWmsApp);
                    if ((data.status === true && data.result !== '') && (data2.status === true && data2.result !== '')) {
                        let totalOrderShip = parseFloat(data.result) + parseFloat(data2.result);

                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_shipped_before_migration = totalOrderShip;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalOrderShippedBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderShippedBeforeMigration',
                            value: totalOrderShip, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_SHIPPED);

                        continue;
                    } else if ((data.status === true && data.result === '') && (data2.status === true && data2.result === '')) {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderShippedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_SHIPPED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderShippedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_SHIPPED);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_ORDER_COMPLETED_BEFORE_MIGRATION) {
            if (stepMigration.total_order_completed_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderCompletedBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderCompletedBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_COMPLETED}`);
    
                    continue;
                } else {
                    let data = await totalOrderCompleted(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_completed_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalOrderCompletedBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderCompletedBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_COMPLETED);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderCompletedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_COMPLETED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderCompletedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_COMPLETED);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_ORDER_FAILED_BEFORE_MIGRATION) {
            if (stepMigration.total_order_failed_before_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderFailedBeforeMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderFailedBeforeMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_FAILED}`);
    
                    continue;
                } else {
                    let data = await totalOrderFailed(token, instanceWmsApp);
                    if (data.status === true && data.result !== '') {
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_failed_before_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);

                        // Set state value totalOrderFailedBeforeMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderFailedBeforeMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_FAILED);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderFailedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_FAILED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderFailedBeforeMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_FAILED);

                        break;
                    }
                }
            }
        }

        // Step 2
        if (obj.name === STEPS.MIGRATE_TO_APP) {
            if (skipMigration.migrateToApp === true) {
                // Notif
                notifMessage(STATUS.SUCCESS, `Skip ${LABEL.MIGRATE_TO_APP}`);
    
                continue;
            } else {
                if (!stepMigration.migrate_to_app || stepMigration.migrate_to_app === STEP_PROCESS.FAILED || stepMigration.migrate_to_app === STEP_PROCESS.PROCESSING) {
                    // Set state value valMigrateToApp
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'valMigrateToApp',
                        value: 0, 
                    }));

                    // Set state value wait process label
                    dispatch(HandleChangeStateWaitProcessLabel({
                        name: 'migrateToApp',
                        value: STEP_PROCESS.PROCESSING, 
                    }));
    
                    // Get Data Wms
                    objStepMigration.step_migration = await getDataWms(token);
    
                    try {
                        await appMigrationProcess(token);
    
                        // disable trigger per table
                        await disableEnableMigrateToAppProcess(token, 'DISABLE');

                        // Service update app migrate
                        objStepMigration.step_migration.migrate_to_app = STEP_PROCESS.PROCESSING;
                        await updateWmsMigration(objStepMigration, token);

                        // no longer called from fe
                        // await updateAppMigrationProcess(token); // Set 0 migration process
    
                        // Set state value migrateToApp
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'migrateToApp',
                            value: STEP_PROCESS.PROCESSING, 
                        }));
    
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.MIGRATE_TO_APP);
    
                        break;
                    } catch (error) {
                        const { message } = error.response.data;

                        // Set state value msgMigrateToApp
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'msgMigrateToApp',
                            value: message, 
                        }));

                        // Service update wms migrate
                        objStepMigration.step_migration.migrate_to_app = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
    
                        // Set state value migrateToApp
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'migrateToApp',
                            value: STEP_PROCESS.FAILED, 
                        }));
    
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'migrateToApp',
                            value: true, // Set Value Here
                        }));
    
                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.MIGRATE_TO_APP);
    
                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TURN_OFF_INTEGRATION) {
            if (!stepMigration.turn_off_integration || stepMigration.turn_off_integration === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'turnOffIntegration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                // Get MP
                let data = await mPIntegration(token, instanceWmsApp);

                // get value active true
                let filterActive = data.result.filter((obj) => (obj.is_active === true || obj.pause_sales_download === false));
                if (filterActive.length > 0) {
                    try {
                        // Update MP
                        filterActive.map(async (val) => {
                            await turnOffMp({ storeId: val.store_id}, token, instanceWmsApp)
                        });

                        // Service update wms migrate
                        objStepMigration.step_migration.turn_off_integration = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);

                        // Service update marketplace
                        await updateWmsMigrationMp({
                            marketplace: filterActive
                        }, token);

                        // Set state value turnOffIntegration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'turnOffIntegration',
                            value: STEP_PROCESS.FINISHED, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TURN_OFF_INTEGRATION);

                        continue;
                    } catch (error) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.turn_off_integration = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value turnOffIntegration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'turnOffIntegration',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'turnOffIntegration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TURN_OFF_INTEGRATION);

                        break;
                    }
                } else {
                    // Update table wms
                    objStepMigration.marketplace = data.result;
                    await updateWmsMigrationMp(objStepMigration, token);
                    
                    // Service update wms migrate
                    objStepMigration.step_migration.turn_off_integration = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value turnOffIntegration
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'turnOffIntegration',
                        value: STEP_PROCESS.FINISHED, 
                    }));

                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.TURN_OFF_INTEGRATION);

                    continue;
                }
            }
        }

        if (obj.name === STEPS.SIGNED_AS_WMS) {
            if (!stepMigration.signed_as_wms || stepMigration.signed_as_wms === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'signedAsWms',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    await updateFlagWmsMigration(token);

                    // Service update wms migrate
                    objStepMigration.step_migration.signed_as_wms = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);

                    // Set state value signedAsWms
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'signedAsWms',
                        value: STEP_PROCESS.FINISHED, 
                    }));

                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.SIGNED_AS_WMS);

                    continue;
                } catch (error) {
                    // Service update wms migrate
                    objStepMigration.step_migration.signed_as_wms = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);

                    // Set state value signedAsWms
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'signedAsWms',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'signedAsWms',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.SIGNED_AS_WMS);

                    break;
                }
            }
        }

        if (obj.name === STEPS.WMS_MIGRATION_PROCESS) {
            if (skipMigration.wmsMigrationProcess === true) {
                // Notif
                notifMessage(STATUS.SUCCESS, `Skip ${LABEL.WMS_MIGRATION_PROCESS}`);
    
                continue;
            } else {
                if (!stepMigration.wms_migration_process || stepMigration.wms_migration_process === STEP_PROCESS.FAILED || stepMigration.wms_migration_process === STEP_PROCESS.PROCESSING) {
                    // Set state value valWmsMigrationProcess
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'valWmsMigrationProcess',
                        value: 0, 
                    }));

                    // Set state value wait process label
                    dispatch(HandleChangeStateWaitProcessLabel({
                        name: 'wmsMigrationProcess',
                        value: STEP_PROCESS.PROCESSING, 
                    }));
    
                    // Get Data Wms
                    objStepMigration.step_migration = await getDataWms(token);
    
                    try {
                        await wmsMigrationProcess(token);
    
                        // Service update wms migrate
                        objStepMigration.step_migration.wms_migration_process = STEP_PROCESS.PROCESSING;
                        await updateWmsMigration(objStepMigration, token);

                        // no longer called from fe
                        // await updateWmsMigrationProcess(token); // Set 0 migration process
    
                        // Set state value wmsMigrationProcess
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'wmsMigrationProcess',
                            value: STEP_PROCESS.PROCESSING, 
                        }));
    
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.WMS_MIGRATION_PROCESS);
    
                        break;
                    } catch (error) {
                        const { message } = error.response.data;

                        // Set state value msgWmsMigrationProcess
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'msgWmsMigrationProcess',
                            value: message, 
                        }));

                        // Service update wms migrate
                        objStepMigration.step_migration.wms_migration_process = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
    
                        // Set state value wmsMigrationProcess
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'wmsMigrationProcess',
                            value: STEP_PROCESS.FAILED, 
                        }));
    
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'wmsMigrationProcess',
                            value: true, // Set Value Here
                        }));
    
                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.WMS_MIGRATION_PROCESS);
    
                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.DEFAULT_BIN_ITEM_MOVEMENT_PROCESS) {
            if (!stepMigration.default_bin_item_movement_process || stepMigration.default_bin_item_movement_process === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'defaultBinItemMovementProcess',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Disable Trigger
                    await disableEnableDefaultBinItemMovementProcess(token, 'DISABLE');

                    const resultCount = await countDefaultBinItemMovementProcess(token);
                    const limit = LIMIT_EXEC_IM;
                    let { bill, adj, purchRet, inv, invBundle, salesRet, itemTransfer, archive, location } = resultCount.data.data;
                    
                    while (bill > 0) {
                        // Update
                        await defaultBinItemMovementProcess(token, 'billDetail', limit);

                        bill = bill - limit;
                    }

                    while (adj > 0) {
                        // Update
                        await defaultBinItemMovementProcess(token, 'adjDetail', limit);

                        adj = adj - limit;
                    }

                    while (purchRet > 0) {
                        // Update
                        await defaultBinItemMovementProcess(token, 'purchRetDetail', limit);

                        purchRet = purchRet - limit;
                    }

                    while (inv > 0) {
                        // Update
                        await defaultBinItemMovementProcess(token, 'invDetail', limit);

                        inv = inv - limit;
                    }

                    while (invBundle > 0) {
                        // Update
                        await defaultBinItemMovementProcess(token, 'invBundleDetail', limit);

                        invBundle = invBundle - limit;
                    }

                    while (salesRet > 0) {
                        // Update
                        await defaultBinItemMovementProcess(token, 'salesRetDetail', limit);

                        salesRet = salesRet - limit;
                    }

                    while (itemTransfer > 0) {
                        // Update
                        await defaultBinItemMovementProcess(token, 'itemTransferDetail', limit);

                        itemTransfer = itemTransfer - limit;
                    }

                    while (archive > 0) {
                        // Update
                        await defaultBinItemMovementProcess(token, 'archive', limit);

                        archive = archive - limit;
                    }

                    while (location > 0) {
                        // Update
                        await defaultBinItemMovementProcess(token, 'location', limit);

                        location = location - limit;
                    }

                    // Enable Trigger
                    await disableEnableDefaultBinItemMovementProcess(token, 'ENABLE');

                    // Service update wms migrate
                    objStepMigration.step_migration.default_bin_item_movement_process = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);

                    // Set state value defaultBinItemMovementProcess
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'defaultBinItemMovementProcess',
                        value: STEP_PROCESS.FINISHED, 
                    }));

                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.DEFAULT_BIN_ITEM_MOVEMENT_PROCESS);

                    continue;                    
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.default_bin_item_movement_process = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value defaultBinItemMovementProcess
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'defaultBinItemMovementProcess',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'defaultBinItemMovementProcess',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.DEFAULT_BIN_ITEM_MOVEMENT_PROCESS);

                    break;
                }
            }
        }

        if (obj.name === STEPS.DEFAULT_BIN_PROCESS) {
            if (!stepMigration.default_bin_process || stepMigration.default_bin_process === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'defaultBinProcess',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Disable Trigger
                    await disableEnableDefaultBinProcess(token, 'DISABLE');

                    const resultCount = await countDefaultBinProcess(token);
                    const limit = LIMIT_EXEC;
                    let { adj, invoice, purch, transfer, salesOrder } = resultCount.data.data;
                    
                    while (adj > 0) {
                        // Update
                        await defaultBinProcess(token, 'adjDetail', limit);

                        adj = adj - limit;
                    }
                    
                    while (purch > 0) {
                        // Update
                        await defaultBinProcess(token, 'purchDetail', limit);
                        
                        purch = purch - limit;
                    }

                    while (invoice > 0) {
                        // Update
                        await defaultBinProcess(token, 'invoiceDetail', limit);
                        
                        invoice = invoice - limit;
                    }

                    while (transfer > 0) {
                        // Update
                        await defaultBinProcess(token, 'transferDetail', limit);
                        
                        transfer = transfer - limit;
                    }

                    while (salesOrder > 0) {
                        // Update
                        await defaultBinProcess(token, 'salesOrderDetail', limit);
                        
                        salesOrder = salesOrder - limit;
                    }

                    // Enable Trigger
                    await disableEnableDefaultBinProcess(token, 'ENABLE');

                    // Service update wms migrate
                    objStepMigration.step_migration.default_bin_process = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);

                    // Set state value defaultBinProcess
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'defaultBinProcess',
                        value: STEP_PROCESS.FINISHED, 
                    }));

                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.DEFAULT_BIN_PROCESS);

                    continue;                    
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.default_bin_process = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value defaultBinProcess
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'defaultBinProcess',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'defaultBinProcess',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.DEFAULT_BIN_PROCESS);

                    break;
                }
            }
        }

        if (obj.name === STEPS.EMPLOYEE_MAPPING) {
            if (!stepMigration.employee_mapping || stepMigration.employee_mapping === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'employeeMapping',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    await employeeMapping(token);
                    
                    // Service update wms migrate
                    objStepMigration.step_migration.employee_mapping = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value employeeMapping
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'employeeMapping',
                        value: STEP_PROCESS.FINISHED, 
                    }));

                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.EMPLOYEE_MAPPING);

                    continue;
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.employee_mapping = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value employeeMapping
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'employeeMapping',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'employeeMapping',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.EMPLOYEE_MAPPING);

                    break;
                }
            }
        }

        if (obj.name === STEPS.ORDER_MAPPING_TO_WMS_STATUS) {
            if (!stepMigration.order_mapping_to_wms_status || stepMigration.order_mapping_to_wms_status === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'orderMappingToWmsStatus',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Disable Trigger
                    await disableEnableOrderMapping(token, 'DISABLE');

                    const resultCount = await countOrderMapping(token);
                    const limit = LIMIT_EXEC;
                    let { unpaid, readyToProcess, pickList, readyToPack, readyToShipped, readyToShipped2, complete } = resultCount.data.data;
                    
                    while (unpaid > 0) {
                        // Update
                        await orderMapping(token, 'unpaid', limit);

                        orderMapping = orderMapping - limit;
                    }
                    
                    while (readyToProcess > 0) {
                        // Update
                        await orderMapping(token, 'readyToProcess', limit);
                        
                        readyToProcess = readyToProcess - limit;
                    }

                    while (pickList > 0) {
                        // Update
                        await orderMapping(token, 'pickList', limit);
                        
                        pickList = pickList - limit;
                    }

                    while (readyToPack > 0) {
                        // Update
                        await orderMapping(token, 'readyToPack', limit);
                        
                        readyToPack = readyToPack - limit;
                    }

                    while (readyToShipped > 0) {
                        // Update
                        await orderMapping(token, 'readyToShipped', limit);
                        
                        readyToShipped = readyToShipped - limit;
                    }

                    while (readyToShipped2 > 0) {
                        // Update
                        await orderMapping(token, 'readyToShipped2', limit);
                        
                        readyToShipped2 = readyToShipped2 - limit;
                    }

                    while (complete > 0) {
                        // Update
                        await orderMapping(token, 'complete', limit);
                        
                        complete = complete - limit;
                    }

                    // Enable Trigger
                    await disableEnableOrderMapping(token, 'ENABLE');
                    
                    // Service update wms migrate
                    objStepMigration.step_migration.order_mapping_to_wms_status = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);

                    // Set state value orderMappingToWmsStatus
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'orderMappingToWmsStatus',
                        value: STEP_PROCESS.FINISHED, 
                    }));
                    
                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.ORDER_MAPPING_TO_WMS_STATUS);

                    continue;
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.order_mapping_to_wms_status = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value orderMappingToWmsStatus
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'orderMappingToWmsStatus',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'orderMappingToWmsStatus',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.ORDER_MAPPING_TO_WMS_STATUS);

                    break;
                }
            }
        }

        if (obj.name === STEPS.SNBN_MAPPING_TO_SNPSN) {
            if (!stepMigration.snbn_mapping_to_snpsn || stepMigration.snbn_mapping_to_snpsn === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'snBnMappingToSnPsn',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Disable Trigger
                    await disableEnableSnmMapping(token, 'DISABLE');

                    const resultCount = await countSnBnMapping(token);
                    const limit = LIMIT_EXEC;
                    let { transfer_in, sales_return, adj_in, 
                        transfer_out, purchase_return,
                        adj_out, invoice, bill, picklist } = resultCount.data.data;
                    
                    while (transfer_in > 0) {
                        // insert
                        await snBnMapping(token, 'transfer_in', limit);

                        transfer_in -= transfer_in;
                    }
                    
                    while (sales_return > 0) {
                        // insert
                        await snBnMapping(token, 'sales_return', limit);
                        
                        sales_return -= limit;
                    }

                    while (adj_in > 0) {
                        // insert
                        await snBnMapping(token, 'adj_in', limit);
                        
                        adj_in -= limit;
                    }

                    while (transfer_out > 0) {
                        // insert
                        await snBnMapping(token, 'transfer_out', limit);
                        
                        transfer_out -= limit;
                    }

                    while (purchase_return > 0) {
                        // insert
                        await snBnMapping(token, 'purchase_return', limit);
                        
                        purchase_return -= limit;
                    }

                    while (adj_out > 0) {
                        // insert
                        await snBnMapping(token, 'adj_out', limit);
                        
                        adj_out -= limit;
                    }

                    while (invoice > 0) {
                        // insert
                        await snBnMapping(token, 'invoice', limit);
                        
                        invoice -= limit;
                    }

                    while (bill > 0) {
                        // Update
                        await snBnMapping(token, 'bill', limit);
                        
                        bill -= limit;
                    }

                    while (picklist > 0) {
                        // Update
                        await snBnMapping(token, 'picklist', limit);
                        
                        picklist -= limit;
                    }
                    // Enable Trigger
                    await disableEnableSnmMapping(token, 'ENABLE');
                    
                    // Service update wms migrate
                    objStepMigration.step_migration.snbn_mapping_to_snpsn = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);

                    // Set state value orderMappingToWmsStatus
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'snBnMappingToSnPsn',
                        value: STEP_PROCESS.FINISHED, 
                    }));
                    
                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.SNBN_MAPPING_TO_SNPSN);

                    continue;
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.snbn_mapping_to_snpsn = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value orderMappingToWmsStatus
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'snBnMappingToSnPsn',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'snBnMappingToSnPsn',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.SNBN_MAPPING_TO_SNPSN);

                    break;
                }
            }
        }

        if (obj.name === STEPS.SNBN_MOVE_TO_SNM) {
            if (!stepMigration.snbn_move_to_snm || stepMigration.snbn_move_to_snm === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'snBnMovetoSnm',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Disable Trigger
                    await disableEnableOrderMovement(token, 'DISABLE');

                    const resultCount = await countSnBnMovement(token);
                    const limit = LIMIT_EXEC;
                    let { transfer_in, sales_return, adj_in, 
                        transfer_out, purchase_return, picklist,
                        adj_out, invoice, bill, default_snm } = resultCount.data.data;
                    
                    while (transfer_in > 0) {
                        // insert
                        await snBnMovement(token, 'transfer_in', limit);

                        transfer_in -= transfer_in;
                    }
                    
                    while (sales_return > 0) {
                        // insert
                        await snBnMovement(token, 'sales_return', limit);
                        
                        sales_return -= limit;
                    }

                    while (adj_in > 0) {
                        // insert
                        await snBnMovement(token, 'adj_in', limit);
                        
                        adj_in -= limit;
                    }

                    while (bill > 0) {
                        // insert
                        await snBnMovement(token, 'bill', limit);
                        
                        bill -= limit;
                    }

                    while (transfer_out > 0) {
                        // insert
                        await snBnMovement(token, 'transfer_out', limit);
                        
                        transfer_out -= limit;
                    }

                    while (purchase_return > 0) {
                        // insert
                        await snBnMovement(token, 'purchase_return', limit);
                        
                        purchase_return -= limit;
                    }

                    while (adj_out > 0) {
                        // insert
                        await snBnMovement(token, 'adj_out', limit);
                        
                        adj_out -= limit;
                    }

                    while (invoice > 0) {
                        // insert
                        await snBnMovement(token, 'invoice', limit);
                        
                        invoice -= limit;
                    }

                    while (picklist > 0) {
                        // insert
                        await snBnMovement(token, 'picklist', limit);
                        
                        picklist -= limit;
                    }

                    while (default_snm > 0) {
                        // Update bill & picklist trx to default rack
                        await snBnMovement(token, 'default_snm', limit);
                        
                        default_snm -= limit;
                    }

                    // Enable Trigger
                    await disableEnableOrderMovement(token, 'ENABLE');
                    
                    // Service update wms migrate
                    objStepMigration.step_migration.snbn_move_to_snm = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);

                    // Set state value orderMappingToWmsStatus
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'snBnMovetoSnm',
                        value: STEP_PROCESS.FINISHED, 
                    }));
                    
                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.SNBN_MOVE_TO_PSN);

                    continue;
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.snbn_move_to_snm = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value orderMappingToWmsStatus
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'snBnMovetoSnm',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'snBnMovetoSnm',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.SNBN_MOVE_TO_PSN);

                    break;
                }
            }
        }

        // Step 3
        if (obj.name === STEPS.BILL_CHECK) {
            if (!stepMigration.bill_check || stepMigration.bill_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'billCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Check Item Movement
                    let result = await checkItemMovementBillDetail(token);

                    if (result.data.data.status === true) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.bill_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value billCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'billCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.BILL_CHECK);

                        continue;
                    } else {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.bill_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value billCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'billCheck',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'billCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.BILL_CHECK);

                        break;
                    }
                } catch (error) {
                    // Set state value billCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'billCheck',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'billCheck',
                        value: true, // Set Value Here
                    }));

                    // Service update wms migrate
                    objStepMigration.step_migration.bill_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.BILL_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.ADJUSTMENT_CHECK) {
            if (!stepMigration.adjustment_check || stepMigration.adjustment_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'adjustmentCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Check Item Movement
                    let result = await checkItemMovementItemAdjDetail(token);

                    if (result.data.data.status === true) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.adjustment_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value adjustmentCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'adjustmentCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.ADJUSTMENT_CHECK);

                        continue;
                    } else {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.adjustment_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value adjustmentCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'adjustmentCheck',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'adjustmentCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.ADJUSTMENT_CHECK);

                        break;
                    }
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.adjustment_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value adjustmentCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'adjustmentCheck',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'adjustmentCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.ADJUSTMENT_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.PURCHASE_RETURN_CHECK) {
            if (!stepMigration.purchase_return_check || stepMigration.purchase_return_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'purchaseReturnCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Check Item Movement
                    let result = await checkItemMovementPurchRetDetail(token);

                    if (result.data.data.status === true) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.purchase_return_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value purchaseReturnCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'purchaseReturnCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.ADJUSTMENT_CHECK);

                        continue;
                    } else {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.purchase_return_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value purchaseReturnCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'purchaseReturnCheck',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'purchaseReturnCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.PURCHASE_RETURN_CHECK);

                        break;
                    }
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.purchase_return_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value purchaseReturnCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'purchaseReturnCheck',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'purchaseReturnCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.PURCHASE_RETURN_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.ORDER_CHECK) {
            if (!stepMigration.order_check || stepMigration.order_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'orderCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Check Item Movement
                    let result = await checkItemMovementInvoiceDetail(token);

                    if (result.data.data.status === true) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.order_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value orderCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'orderCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.ORDER_CHECK);

                        continue;
                    } else {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.order_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value orderCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'orderCheck',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'orderCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.ORDER_CHECK);

                        break;
                    }
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.order_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value orderCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'orderCheck',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'orderCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.ORDER_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.ORDER_ITEM_BUNDLE_CHECK) {
            if (!stepMigration.order_item_bundle_check || stepMigration.order_item_bundle_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'orderItemBundleCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Check Item Movement
                    let result = await checkItemMovementInvoiceDetailBundle(token);

                    if (result.data.data.status === true) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.order_item_bundle_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value orderItemBundleCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'orderItemBundleCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.ORDER_ITEM_BUNDLE_CHECK);

                        continue;
                    } else {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.order_item_bundle_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value orderItemBundleCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'orderItemBundleCheck',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'orderItemBundleCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.ORDER_ITEM_BUNDLE_CHECK);

                        break;
                    }
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.order_item_bundle_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value orderItemBundleCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'orderItemBundleCheck',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'orderItemBundleCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.ORDER_ITEM_BUNDLE_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.SALES_RETURN_CHECK) {
            if (!stepMigration.sales_return_check || stepMigration.sales_return_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'salesReturnCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Check Item Movement
                    let result = await checkItemMovementSalesRetDetail(token);

                    if (result.data.data.status === true) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.sales_return_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value salesReturnCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'salesReturnCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.SALES_RETURN_CHECK);

                        continue;
                    } else {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.sales_return_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value salesReturnCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'salesReturnCheck',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'salesReturnCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.SALES_RETURN_CHECK);

                        break;
                    }
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.sales_return_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value salesReturnCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'salesReturnCheck',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'salesReturnCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.SALES_RETURN_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.TRANSFER_CHECK) {
            if (!stepMigration.transfer_check || stepMigration.transfer_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'transferCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Check Item Movement
                    let result = await checkItemMovementItemTransferDetail(token);

                    if (result.data.data.status === true) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.transfer_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value transferCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'transferCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TRANSFER_CHECK);

                        continue;
                    } else {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.transfer_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value transferCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'transferCheck',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'transferCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TRANSFER_CHECK);

                        break;
                    }
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.transfer_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value transferCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'transferCheck',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'transferCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.TRANSFER_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.ARCHIVE_ITEM_CHECK) {
            if (!stepMigration.archive_item_check || stepMigration.archive_item_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'archiveItemCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Check Item Movement
                    let result = await checkItemMovementArchive(token);

                    if (result.data.data.status === true) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.archive_item_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value archiveItemCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'archiveItemCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.ARCHIVE_ITEM_CHECK);

                        continue;
                    } else {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.archive_item_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value archiveItemCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'archiveItemCheck',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'archiveItemCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.ARCHIVE_ITEM_CHECK);

                        break;
                    }
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.archive_item_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value archiveItemCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'archiveItemCheck',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'archiveItemCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.ARCHIVE_ITEM_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.SNBN_RACK_CHECK) {
            if (!stepMigration.snbn_rack_check || stepMigration.snbn_rack_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'snBnRackCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Check Item Movement
                    let result = await checkSnBnRack(token);

                    if (result.data.data.status === true) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.snbn_rack_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value snBnRackCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'snBnRackCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.SNBN_RACK_CHECK);

                        continue;
                    } else {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.snbn_rack_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value snBnRackCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'snBnRackCheck',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'snBnRackCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.SNBN_RACK_CHECK);

                        break;
                    }
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.snbn_rack_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value snBnRackCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'snBnRackCheck',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'snBnRackCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.SNBN_RACK_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.SNBN_ILLEGAL_CHECK) {
            if (!stepMigration.snbn_illegal_check || stepMigration.snbn_illegal_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'snBnIllegalCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Check Item Movement
                    let result = await checkIllegalSnBn(token);
                    console.log(result.data.data.total);
                    objStepMigration.step_migration.total_snbn_illegal = result.data.data.total;
                    // Set state value snBnRackCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'totalSnbnIllegal',
                        value: result.data.data.total, 
                    }));

                    if (result.data.data.status === true) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.snbn_illegal_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value snBnRackCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'snBnIllegalCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.SNBN_ILLEGAL_CHECK);

                        continue;
                    } else {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.snbn_illegal_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value snBnRackCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'snBnIllegalCheck',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'snBnIllegalCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.SNBN_ILLEGAL_CHECK);

                        break;
                    }
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.snbn_illegal_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value snBnIllegalCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'snBnIllegalCheck',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'snBnIllegalCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.SNBN_ILLEGAL_CHECK);

                    break;
                }
            }
        }

        // Step 4
        if (obj.name === STEPS.DEFAULT_BIN_CHECK) {
            if (!stepMigration.default_bin_check || stepMigration.default_bin_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'defaultBinCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                try {
                    // Check Item Movement
                    let result = await defaultBinCheck(token);

                    if (result.data.data.status === true) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.default_bin_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value defaultBinCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'defaultBinCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.DEFAULT_BIN_CHECK);

                        continue;
                    } else {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.default_bin_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value defaultBinCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'defaultBinCheck',
                            value: STEP_PROCESS.FAILED, 
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'defaultBinCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.DEFAULT_BIN_CHECK);

                        break;
                    }
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.default_bin_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value defaultBinCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'defaultBinCheck',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'defaultBinCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.DEFAULT_BIN_CHECK);

                    break;
                }
            }
        }

        // Step 5
        if (obj.name === STEPS.TOTAL_SKU_AFTER_MIGRATION) {
            if (stepMigration.total_sku_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalSkuAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalSkuAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_SKU}`);
        
                    continue;
                } else {
                    let data = await totalSku(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_sku_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalSkuAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalSkuAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_SKU);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalSkuAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_SKU);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalSkuAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_SKU);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_PO_AFTER_MIGRATION) {
            if (stepMigration.total_po_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalPoAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalPoAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_PO}`);
        
                    continue;
                } else {
                    let data = await totalPo(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_po_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalPoAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalPoAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_PO);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalPoAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_PO);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalPoAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_PO);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_TRANSFER_AFTER_MIGRATION) {
            if (stepMigration.total_transfer_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalTransferAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalTransferAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_TRANSFER}`);
        
                    continue;
                } else {
                    let data = await totalTransfer(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_transfer_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalTransferAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalTransferAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_TRANSFER);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_TRANSFER_TRANSIT_AFTER_MIGRATION) {
            if (stepMigration.total_transfer_transit_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalTransferTransitAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalTransferTransitAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_TRANSFER_TRANSIT}`);
        
                    continue;
                } else {
                    let data = await totalTransferTransit(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_transfer_transit_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalTransferTransitAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalTransferTransitAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_TRANSFER_TRANSIT);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferTransitAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER_TRANSIT);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferTransitAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER_TRANSIT);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_TRANSFER_SHIPPED_AFTER_MIGRATION) {
            if (stepMigration.total_transfer_shipped_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalTransferShippedAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalTransferShippedAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_TRANSFER_SHIPPED}`);
        
                    continue;
                } else {
                    let data = await totalTransferShipped(token, instanceWmsApp2);
                    let data2 = await totalTransferShipped2(token, instanceWmsApp2);
                    if ((data.status === true && data.result !== '') && (data2.status === true && data2.result !== '')) {
                        if (data.result === data2.result) {                            
                            // Get Data Wms
                            objStepMigration.step_migration = await getDataWms(token);
                            
                            // Service update wms migrate
                            objStepMigration.step_migration.total_transfer_shipped_after_migration = data.result;
                            await updateWmsMigration(objStepMigration, token);
                            
                            // Set state value totalTransferShippedAfterMigration
                            dispatch(HandleChangeStateStepMigrate({
                                name: 'totalTransferShippedAfterMigration',
                                value: data.result, 
                            }));

                            // Notif
                            notifMessage(STATUS.SUCCESS, LABEL.TOTAL_TRANSFER_SHIPPED);

                            continue;
                        } else {
                            // Set State and add retry
                            dispatch(HandleChangeStateRetryMigrate({
                                name: 'totalTransferShippedAfterMigration',
                                value: true, // Set Value Here
                            }));

                            // Notif
                            notifMessage(STATUS.SUCCESS, LABEL.TOTAL_TRANSFER_SHIPPED);

                            break;
                        }
                    } else if ((data.status === true && data.result === '') && (data2.status === true && data2.result === '')) {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferShippedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER_SHIPPED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalTransferShippedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_TRANSFER_SHIPPED);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_SALES_RETURN_AFTER_MIGRATION) {
            if (stepMigration.total_sales_return_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalSalesReturnAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalSalesReturnAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_SALES_RETURN}`);
        
                    continue;
                } else {
                    let data = await totalSalesReturn(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_sales_return_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalSalesReturnAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalSalesReturnAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_SALES_RETURN);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalSalesReturnAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_SALES_RETURN);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalSalesReturnAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_SALES_RETURN);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_ONLINE_RETURN_REJECTED_AFTER_MIGRATION) {
            if (stepMigration.total_online_return_rejected_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOnlineReturnRejectedAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOnlineReturnRejectedAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ONLINE_RETURN_REJECTED}`);
        
                    continue;
                } else {
                    let data = await totalOnlineReturnRejected(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_online_return_rejected_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalOnlineReturnRejectedAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOnlineReturnRejectedAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ONLINE_RETURN_REJECTED);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOnlineReturnRejectedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ONLINE_RETURN_REJECTED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOnlineReturnRejectedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ONLINE_RETURN_REJECTED);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_ONLINE_RETURN_APPROVED_AFTER_MIGRATION) {
            if (stepMigration.total_online_return_approved_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOnlineReturnApprovedAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOnlineReturnApprovedAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ONLINE_RETURN_APPROVED}`);
        
                    continue;
                } else {
                    let data = await totalOnlineReturnApproved(token, instanceWmsApp2);
                    let data2 = await totalOnlineReturnApproved2(token, instanceWmsApp2);
                    if ((data.status === true && data.result !== '') && (data2.status === true && data2.result !== '')) {
                        let result = parseFloat(data.result) + parseFloat(data2.result);
                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_online_return_approved_after_migration = result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalOnlineReturnApprovedAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOnlineReturnApprovedAfterMigration',
                            value: result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ONLINE_RETURN_APPROVED);

                        continue;
                    } else if ((data.status === true && data.result === '') && (data2.status === true && data2.result === '')) {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOnlineReturnApprovedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ONLINE_RETURN_APPROVED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOnlineReturnApprovedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ONLINE_RETURN_APPROVED);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_BILL_AFTER_MIGRATION) {
            if (stepMigration.total_bill_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalBillAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalBillAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_BILL}`);
        
                    continue;
                } else {
                    let data = await totalBill(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_bill_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalBillAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalBillAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_BILL);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalBillAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_BILL);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalBillAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_BILL);

                        break;
                    }
                }
            }
        }

        if (obj.name === STEPS.TOTAL_INVOICE_AFTER_MIGRATION) {
            if (stepMigration.total_invoice_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalInvoiceAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalInvoiceAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_INVOICE}`);
        
                    continue;
                } else {
                    let data = await totalInvoice(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_invoice_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalInvoiceAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalInvoiceAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_INVOICE);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalInvoiceAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_INVOICE);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalInvoiceAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_INVOICE);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_ADJUSTMENT_AFTER_MIGRATION) {
            if (stepMigration.total_adjustment_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalAdjustmentAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalAdjustmentAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ADJUSTMENT}`);
        
                    continue;
                } else {
                    let data = await totalAdjustment(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_adjustment_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalAdjustmentAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalAdjustmentAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ADJUSTMENT);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalAdjustmentAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ADJUSTMENT);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalAdjustmentAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ADJUSTMENT);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_ORDER_NOT_PAID_AFTER_MIGRATION) {
            if (stepMigration.total_order_not_paid_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderNotPaidAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderNotPaidAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_NOT_PAID}`);
        
                    continue;
                } else {
                    let data = await totalOrderNotPaid(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_not_paid_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalOrderNotPaidAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderNotPaidAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_NOT_PAID);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderNotPaidAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_NOT_PAID);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderNotPaidAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_NOT_PAID);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_ORDER_CANCEL_AFTER_MIGRATION) {
            if (stepMigration.total_order_cancel_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderCancelAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderCancelAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_CANCEL}`);
        
                    continue;
                } else {
                    let data = await totalOrderCancel(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_cancel_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalOrderCancelAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderCancelAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_CANCEL);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderCancelAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_CANCEL);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderCancelAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_CANCEL);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_ORDER_READY_TO_PICK_AFTER_MIGRATION) {
            if (stepMigration.total_order_ready_to_pick_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderReadyToPickAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderReadyToPickAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_READY_TO_PICK}`);
        
                    continue;
                } else {
                    let data = await totalOrderReady(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_ready_to_pick_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalOrderReadyToPickAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderReadyToPickAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_READY_TO_PICK);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderReadyToPickAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_READY_TO_PICK);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderReadyToPickAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_READY_TO_PICK);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_ORDER_CONFIRM_PICK_AFTER_MIGRATION) {
            if (stepMigration.total_order_confirm_pick_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderConfirmPickAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderConfirmPickAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_CONFIRM_PICK}`);
        
                    continue;
                } else {
                    let data = await totalOrderConfirmPick(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_confirm_pick_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalOrderConfirmPickAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderConfirmPickAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_CONFIRM_PICK);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderConfirmPickAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_CONFIRM_PICK);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderConfirmPickAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_CONFIRM_PICK);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_ORDER_READY_TO_SHIP_AFTER_MIGRATION) {
            if (stepMigration.total_order_ready_to_ship_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderReadyToShipAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderReadyToShipAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_READY_TO_SHIP}`);
        
                    continue;
                } else {
                    let data = await totalOrderReadyShip(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_ready_to_ship_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalOrderReadyToShipAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderReadyToShipAfterMigration',
                            value: data.result, 
                        }));
                        
                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_READY_TO_SHIP);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderReadyToShipAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_READY_TO_SHIP);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderReadyToShipAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_READY_TO_SHIP);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_ORDER_SHIPPED_AFTER_MIGRATION) {
            if (stepMigration.total_order_shipped_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderShippedAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderShippedAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_SHIPPED}`);
        
                    continue;
                } else {
                    let data = await totalOrderShipped(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);

                        // Check Compare Data Order Shipped
                        let data2 = await totalShippedOrderAppWithReadyToShipApp2(token);
                        let data3 = await totalDuplicateReadyToShipWithShippedOrder(token);
                        let data4 = await totalWarehouseStatusReadyToShipNotShowInReadyToShip(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_shipped_order_app_with_ready_to_ship_app2 = parseFloat(data2.result);
                        objStepMigration.step_migration.total_duplicate_ready_to_ship_with_shipped_order = parseFloat(data3.result);
                        objStepMigration.step_migration.total_warehouse_status_ready_to_ship_not_show_in_ready_to_ship = parseFloat(data4.result);

                        objStepMigration.step_migration.total_order_shipped_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalShippedOrderAppWithReadyToShipApp2
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalShippedOrderAppWithReadyToShipApp2',
                            value: parseFloat(data2.result), 
                        }));

                        // Set state value totalDuplicateReadyToShipWithShippedOrder
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalDuplicateReadyToShipWithShippedOrder',
                            value: parseFloat(data3.result), 
                        }));

                        // Set state value totalWarehouseStatusReadyToShipNotShowInReadyToShip
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalWarehouseStatusReadyToShipNotShowInReadyToShip',
                            value: parseFloat(data4.result), 
                        }));

                        // Set state value totalOrderShippedAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderShippedAfterMigration',
                            value: data.result, 
                        }));


                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_SHIPPED);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderShippedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_SHIPPED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderShippedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_SHIPPED);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_ORDER_COMPLETED_AFTER_MIGRATION) {
            if (stepMigration.total_order_completed_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderCompletedAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderCompletedAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_COMPLETED}`);
        
                    continue;
                } else {
                    let data = await totalOrderCompleted(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_completed_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalOrderCompletedAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderCompletedAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_COMPLETED);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderCompletedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_COMPLETED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderCompletedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_COMPLETED);

                        break;
                    }
                }
            }
        }
        
        if (obj.name === STEPS.TOTAL_ORDER_FAILED_AFTER_MIGRATION) {
            if (stepMigration.total_order_failed_after_migration === '') {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'totalOrderFailedAfterMigration',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                if (skipMigration.totalOrderFailedAfterMigration === true) {
                    // Notif
                    notifMessage(STATUS.SUCCESS, `Skip ${LABEL.TOTAL_ORDER_FAILED}`);
        
                    continue;
                } else {
                    let data = await totalOrderFailed(token, instanceWmsApp2);
                    if (data.status === true && data.result !== '') {                        
                        // Get Data Wms
                        objStepMigration.step_migration = await getDataWms(token);
                        
                        // Service update wms migrate
                        objStepMigration.step_migration.total_order_failed_after_migration = data.result;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value totalOrderFailedAfterMigration
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'totalOrderFailedAfterMigration',
                            value: data.result, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TOTAL_ORDER_FAILED);

                        continue;
                    } else if (data.status === true && data.result === '') {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderFailedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_FAILED);

                        break;
                    } else {
                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'totalOrderFailedAfterMigration',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TOTAL_ORDER_FAILED);

                        break;
                    }
                }
            }
        }


        // Step 6
        if (obj.name === STEPS.BILL_READONLY_CHECK) {
            if (!stepMigration.bill_readonly_check || stepMigration.bill_readonly_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'billReadonlyCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                let data = await totalBill(token, instanceWmsApp2);
                let data2 = await totalBillReadonlyCheck(token);

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                if ((data.status === true && data.result !== '') && (data2.status === true && data2.result !== '')) {
                    if (data.result === data2.result) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.bill_readonly_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value billReadonlyCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'billReadonlyCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.BILL_READONLY_CHECK);

                        continue;
                    } else {
                        // Service update wms migrate
                        objStepMigration.step_migration.bill_readonly_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'billReadonlyCheck',
                            value: STEP_PROCESS.FAILED, // Set Value Here
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'billReadonlyCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.BILL_READONLY_CHECK);

                        break;
                    }
                } else {
                    // Service update wms migrate
                    objStepMigration.step_migration.bill_readonly_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'billReadonlyCheck',
                        value: STEP_PROCESS.FAILED, // Set Value Here
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'billReadonlyCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.BILL_READONLY_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.TRANSFER_READONLY_CHECK) {
            if (!stepMigration.transfer_readonly_check || stepMigration.transfer_readonly_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'transferReadonlyCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                let data = await totalTransferShipped2(token, instanceWmsApp2);
                let data2 = await totalTransferReadonlyCheck(token);

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                if ((data.status === true && data.result !== '') && (data2.status === true && data2.result !== '')) {
                    if (data.result === data2.result) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.transfer_readonly_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value transferReadonlyCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'transferReadonlyCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.TRANSFER_READONLY_CHECK);

                        continue;
                    } else {
                        // Service update wms migrate
                        objStepMigration.step_migration.transfer_readonly_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'transferReadonlyCheck',
                            value: STEP_PROCESS.FAILED, // Set Value Here
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'transferReadonlyCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.TRANSFER_READONLY_CHECK);

                        break;
                    }
                } else {
                    // Service update wms migrate
                    objStepMigration.step_migration.transfer_readonly_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'transferReadonlyCheck',
                        value: STEP_PROCESS.FAILED, // Set Value Here
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'transferReadonlyCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.TRANSFER_READONLY_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.SALES_RETURN_READONLY_CHECK) {
            if (!stepMigration.sales_return_readonly_check || stepMigration.sales_return_readonly_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'salesReturnReadonlyCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                let data = await totalSalesReturn(token, instanceWmsApp2);
                let data2 = await totalSalesReturnReadonlyCheck(token);

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                if ((data.status === true && data.result !== '') && (data2.status === true && data2.result !== '')) {
                    if (data.result === data2.result) {                        
                        // Service update wms migrate
                        objStepMigration.step_migration.sales_return_readonly_check = STEP_PROCESS.FINISHED;
                        await updateWmsMigration(objStepMigration, token);
                        
                        // Set state value salesReturnReadonlyCheck
                        dispatch(HandleChangeStateStepMigrate({
                            name: 'salesReturnReadonlyCheck',
                            value: STEP_PROCESS.FINISHED, 
                        }));

                        // Notif
                        notifMessage(STATUS.SUCCESS, LABEL.SALES_RETURN_READONLY_CHECK);

                        continue;
                    } else {
                        // Service update wms migrate
                        objStepMigration.step_migration.sales_return_readonly_check = STEP_PROCESS.FAILED;
                        await updateWmsMigration(objStepMigration, token);

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'salesReturnReadonlyCheck',
                            value: STEP_PROCESS.FAILED, // Set Value Here
                        }));

                        // Set State and add retry
                        dispatch(HandleChangeStateRetryMigrate({
                            name: 'salesReturnReadonlyCheck',
                            value: true, // Set Value Here
                        }));

                        // Notif
                        notifMessage(STATUS.ERROR, LABEL.SALES_RETURN_READONLY_CHECK);

                        break;
                    }
                } else {
                    // Service update wms migrate
                    objStepMigration.step_migration.sales_return_readonly_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'salesReturnReadonlyCheck',
                        value: STEP_PROCESS.FAILED, // Set Value Here
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'salesReturnReadonlyCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.SALES_RETURN_READONLY_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.ENABLE_TRIGGER_CHECK) {
            if (!stepMigration.enable_trigger_check || stepMigration.enable_trigger_check === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'enableTriggerCheck',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                let data = await getEnableTriggerCheck(token);

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);

                if (data.status === true && Array.isArray(data.result.data) && data.result.data.length < 1) {
                    // Service update wms migrate
                    objStepMigration.step_migration.enable_trigger_check = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value enableTriggerCheck
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'enableTriggerCheck',
                        value: STEP_PROCESS.FINISHED, 
                    }));

                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.ENABLE_TRIGGER_CHECK);

                    continue;
                } else {
                    // Service update wms migrate
                    objStepMigration.step_migration.enable_trigger_check = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'enableTriggerCheck',
                        value: STEP_PROCESS.FAILED, // Set Value Here
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'enableTriggerCheck',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.ENABLE_TRIGGER_CHECK);

                    break;
                }
            }
        }

        if (obj.name === STEPS.UPDATE_ACCOUNT_SUPPORT) {
            if (!stepMigration.update_account_support || stepMigration.update_account_support === STEP_PROCESS.FAILED) {
                // Set state value wait process label
                dispatch(HandleChangeStateWaitProcessLabel({
                    name: 'updateAccountSupport',
                    value: STEP_PROCESS.PROCESSING, 
                }));

                // Get Data Wms
                objStepMigration.step_migration = await getDataWms(token);
            
                try {
                    await updateAccountSupport(token);
            
                    // Service update wms migrate
                    objStepMigration.step_migration.update_account_support = STEP_PROCESS.FINISHED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value updateAccountSupport
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'updateAccountSupport',
                        value: STEP_PROCESS.FINISHED, 
                    }));

                    dispatch(HandleChangeStateGlobal({
                        name: 'hideClose',
                        value: false,
                    }));

                    // Notif
                    notifMessage(STATUS.SUCCESS, LABEL.UPDATE_ACCOUNT_SUPPORT);
            
                    continue;
                } catch (error) {                    
                    // Service update wms migrate
                    objStepMigration.step_migration.update_account_support = STEP_PROCESS.FAILED;
                    await updateWmsMigration(objStepMigration, token);
                    
                    // Set state value updateAccountSupport
                    dispatch(HandleChangeStateStepMigrate({
                        name: 'updateAccountSupport',
                        value: STEP_PROCESS.FAILED, 
                    }));

                    // Set State and add retry
                    dispatch(HandleChangeStateRetryMigrate({
                        name: 'updateAccountSupport',
                        value: true, // Set Value Here
                    }));

                    // Notif
                    notifMessage(STATUS.ERROR, LABEL.UPDATE_ACCOUNT_SUPPORT);
            
                    break;
                }
            }
        }
    }
};
