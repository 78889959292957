import React from 'react';
import { Card } from 'reactstrap';
import Block1 from './Block1';
import Block2 from './Block2';
import Block3 from './Block3';
import Block4 from './Block4';

class Print extends React.Component {
    render() {
        return (
            <Card className="main-card mb-3">
                <Block1 />
                <Block2 />
                <Block3 />
                <Block4 />
            </Card>
        )
    }
}

export default Print;