import { instanceWmsApp2, instanceWmsApp } from "./instance";

// Check Tenant Info
export const checkTenantInfo = (data, token) => {
    return instanceWmsApp2.post("/migration/tenant-info", {
        email: data.ownerEmail,
        companyName: data.companyName,
        tenantId: data.tenantId,
        companyId: data.companyId,
    }, {
        headers: {
            'Authorization': `${token}`
        }
    });
};

// Check Item Movement
export const checkItemMovement = (url, token) => {
    return instanceWmsApp2.get(url, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Check Tenant WMS Migrate
export const checkTenantWmsMigrate = (url, token) => {
    return instanceWmsApp2.get(url, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Update Flag WMS Migrate
export const updateFlagWmsMigrate = (url, token) => {
    return instanceWmsApp2.put(url, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Get Data WMS Migrate
export const getDataWmsMigration = (token) => {
    return instanceWmsApp2.get("/migration/wms-migration/get", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Get List Data WMS Migrate
export const getListDataWmsMigration = (token, params) => {
    return instanceWmsApp2.get("/migration/wms-migration/get-list", {
        params: params,
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Insert WMS Migration
export const insertWmsMigration = (payload, token) => {
    return instanceWmsApp2.post("/migration/wms-migration/insert", payload, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Update WMS Migration
export const updateWmsMigration = (payload, token) => {
    return instanceWmsApp2.put("/migration/wms-migration/update", payload, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Update WMS Migration Process
export const updateWmsMigrationProcess = (token) => {
    return instanceWmsApp2.put("/migration/wms-migration/update-wms-migration-process", {}, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Update Flag WMS Migration
export const updateFlagWmsMigration = (token) => {
    return instanceWmsApp2.put("/migration/signed-as-wms", {}, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Update WMS Migration Market Place
export const updateWmsMigrationMp = (payload, token) => {
    return instanceWmsApp2.put("/migration/wms-migration/update-mp", payload, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total SKU
export const getTotalSKU = (token, service) => {
    return service.get("/migration/total-sku", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total PO
export const getTotalPo = (token, service) => {
    return service.get("/migration/total-po", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Transfer
export const getTotalTransfer = (token, service) => {
    return service.get("/migration/total-transfer", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Transfer Transit
export const getTotalTransferTransit = (token, service) => {
    return service.get("/migration/total-transfer-transit", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Transfer Shipped
export const getTotalTransferShipped = (token, service) => {
    return service.get("/migration/total-transfer-shipped", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Transfer Shipped2
export const getTotalTransferShipped2 = (token, service) => {
    return service.get("/migration/total-transfer-shipped2", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Sales Return
export const getTotalSalesReturn = (token, service) => {
    return service.get("/migration/total-sales-return", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};


// Total Online Return Reject
export const getTotalOnlineReturnRejected = (token, service) => {
    return service.get("/migration/total-online-return-rejected", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Online Return Approve
export const getTotalOnlineReturnApproved = (token, service) => {
    return service.get("/migration/total-online-return-approved", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Online Return Approve2
export const getTotalOnlineReturnApproved2 = (token, service) => {
    return service.get("/migration/total-online-return-approved2", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Bill
export const getTotalBill = (token, service) => {
    return service.get("/migration/total-bill", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Invoice
export const getTotalInvoice = (token, service) => {
    return service.get("/migration/total-invoice", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Adjustment
export const getTotalAdjustment = (token, service) => {
    return service.get("/migration/total-adjustment", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Purchase Return
export const getTotalPurchaseReturn = (token, service) => {
    return service.get("/migration/total-purchase-return", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Order Not Paid
export const getTotalOrderNotPaid = (token, service) => {
    return service.get("/migration/total-order-not-paid", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Order Cancel
export const getTotalOrderCancel = (token, service) => {
    return service.get("/migration/total-order-cancel", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Order Ready
export const getTotalOrderReady = (token, service) => {
    return service.get("/migration/total-order-ready-to-pick", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Order Confirm Pick
export const getTotalOrderConfirmPick = (token, service) => {
    return service.get("/migration/total-order-confirm-pick", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Order Ready Ship
export const getTotalOrderReadyShip = (token, service) => {
    return service.get("/migration/total-order-ready-to-ship", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Order Shipped
export const getTotalOrderShipped = (token, service) => {
    return service.get("/migration/total-order-shipped", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Order Shipped2
export const getTotalOrderShipped2 = (token, service) => {
    return service.get("/migration/total-order-shipped2", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Order Completed
export const getTotalOrderCompleted = (token, service) => {
    return service.get("/migration/total-order-completed", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Total Order Failed
export const getTotalOrderFailed = (token, service) => {
    return service.get("/migration/total-order-failed", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Get Market Place
export const getMPIntegration = (token, service) => {
    return service.get("/migration/get-mp-integration", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// APP Migration Process
export const appMigrationProcess = (token) => {
    return instanceWmsApp.post("/migration/app-migration-process", {}, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Update APP Migration Process
export const updateAppMigrationProcess = (token) => {
    return instanceWmsApp.put("/migration/wms-migration/update-app-migration-process", {}, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Disable Enable APP Migration Process
export const disableEnableMigrateToAppProcess = (token, type) => {
    return instanceWmsApp2.post("/migration/dis-enab-trigger-migrate-to-app-process", {
        type: type
    }, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Turn Off Integration
export const turnOffIntegration = (data, token, service) => {
    return service.post("/migration/turn-off-integration", data, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// WMS Migration Process
export const wmsMigrationProcess = (token) => {
    return instanceWmsApp2.post("/migration/wms-migration-process", {}, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Disable Enable Default Bin Item Movement Process
export const disableEnableDefaultBinItemMovementProcess = (token, type) => {
    return instanceWmsApp2.post("/migration/dis-enab-default-bin-item-movement-process", {
        type: type
    }, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Count Default Bin Item Movement Process
export const countDefaultBinItemMovementProcess = (token) => {
    return instanceWmsApp2.get("/migration/count-default-bin-item-movement-process", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Default Bin Item Movement Process
export const defaultBinItemMovementProcess = (token, state, limit) => {
    return instanceWmsApp2.post("/migration/default-bin-item-movement-process", {
        state: state,
        limit: limit,
    }, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Disable Enable Default Bin Process
export const disableEnableDefaultBinProcess = (token, type) => {
    return instanceWmsApp2.post("/migration/dis-enab-default-bin-process", {
        type: type
    }, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Count Default Bin Process
export const countDefaultBinProcess = (token) => {
    return instanceWmsApp2.get("/migration/count-default-bin-process", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Default Bin Process
export const defaultBinProcess = (token, state, limit) => {
    return instanceWmsApp2.post("/migration/default-bin-process", {
        state: state,
        limit: limit,
    }, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Employee Mapping
export const employeeMapping = (token) => {
    return instanceWmsApp2.post("/migration/employee-mapping", {}, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Disable Enable Order Mapping
export const disableEnableOrderMapping = (token, type) => {
    return instanceWmsApp2.post("/migration/dis-enab-order-mapping", {
        type: type
    }, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Count Order Mapping
export const countOrderMapping = (token) => {
    return instanceWmsApp2.get("/migration/count-order-mapping", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Order Mapping
export const orderMapping = (token, state, limit) => {
    return instanceWmsApp2.post("/migration/order-mapping", {
        state: state,
        limit: limit,
    }, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

/* Start Check Item Movement - Check Rak All TRX */

// checkItemMovementBillDetail
export const checkItemMovementBillDetail = (token) => {
    return instanceWmsApp2.get("/migration/check-item-movement/bill-detail", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// checkItemMovementItemAdjDetail
export const checkItemMovementItemAdjDetail = (token) => {
    return instanceWmsApp2.get("/migration/check-item-movement/item-adj-detail", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// checkItemMovementPurchRetDetail
export const checkItemMovementPurchRetDetail = (token) => {
    return instanceWmsApp2.get("/migration/check-item-movement/purch-ret-detail", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// checkItemMovementInvoiceDetail
export const checkItemMovementInvoiceDetail = (token) => {
    return instanceWmsApp2.get("/migration/check-item-movement/invoice-detail", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// checkItemMovementInvoiceDetailBundle
export const checkItemMovementInvoiceDetailBundle = (token) => {
    return instanceWmsApp2.get("/migration/check-item-movement/invoice-detail-bundle", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// checkItemMovementSalesRetDetail
export const checkItemMovementSalesRetDetail = (token) => {
    return instanceWmsApp2.get("/migration/check-item-movement/sales-ret-detail", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// checkItemMovementItemTransferDetail
export const checkItemMovementItemTransferDetail = (token) => {
    return instanceWmsApp2.get("/migration/check-item-movement/transfer-detail", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// checkItemMovementArchive
export const checkItemMovementArchive = (token) => {
    return instanceWmsApp2.get("/migration/check-item-movement/archive", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// checkSnBnRack
export const checkSnBnRack = (token) => {
    return instanceWmsApp2.get("/migration/check-snbn-rack", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// checkSnBnRack
export const checkIllegalSnBn = (token) => {
    return instanceWmsApp2.get("/migration/check-illegal-snbn", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

/* End Check Item Movement - Check Rak All TRX */

// Default Bin Check
export const defaultBinCheck = (token) => {
    return instanceWmsApp2.get("/migration/default-bin-check", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

export const updateAccountSupport = (token) => {
    return instanceWmsApp2.post("/migration/update-account-support", {}, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Turn On Integration
export const turnOnIntegration = (data, token) => {
    return instanceWmsApp2.post("/migration/turn-on-integration", data, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Turn On Sync Order
export const turnOnSyncOrder = (data, token) => {
    return instanceWmsApp2.post("/migration/turn-on-sync-order", data, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Check Shipper Order With Ready To Shipped
export const checkShippedOrderAppWithReadyToShipApp2 = (token) => {
    return instanceWmsApp2.get("/migration/check-shipped-order-app-with-ready-to-ship-app2", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Check Duplicate Ready To Ship With Shipped Order
export const checkDuplicateReadyToShipWithShippedOrder = (token) => {
    return instanceWmsApp2.get("/migration/check-duplicate-ready-to-ship-with-shipped-order", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Check Warehouse Status Ready To Ship Not Show In Ready To Ship
export const checkWarehouseStatusReadyToShipNotShowInReadyToShip = (token) => {
    return instanceWmsApp2.get("/migration/check-warehouse-status-ready-to-ship-not-show-in-ready-to-ship", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Bill Readonly Check
export const billReadonlyCheck = (token) => {
    return instanceWmsApp2.get("/migration/bill-readonly-check", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Transfer Readonly Check
export const transferReadonlyCheck = (token) => {
    return instanceWmsApp2.get("/migration/transfer-readonly-check", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Sales Return Readonly Check
export const salesReturnReadonlyCheck = (token) => {
    return instanceWmsApp2.get("/migration/sales-return-readonly-check", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Enable Trigger Check
export const enableTriggerCheck = (token) => {
    return instanceWmsApp2.get("/migration/enable-trigger-check", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Disable Enable serial_number, picked_serial_number & serial_number_movement table
export const disableEnableSnmMapping = (token, type) => {
    return instanceWmsApp2.post("/migration/dis-enab-snbn-mapping", {
        type: type
    }, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Count SNBN Mapping to SN / PSN
export const countSnBnMapping = (token) => {
    return instanceWmsApp2.get("/migration/count-snbn-mapping", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// SNBN Mapping to SN/PSN
export const snBnMapping = (token, state, limit) => {
    return instanceWmsApp2.post("/migration/snbn-mapping-process", {
        state: state,
        limit: limit,
    }, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Disable Enable serial_number_movement table
export const disableEnableOrderMovement = (token, type) => {
    return instanceWmsApp2.post("/migration/dis-enab-snbn-movement", {
        type: type
    }, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// Count SNBN Movement to serial_number_movement table
export const countSnBnMovement = (token) => {
    return instanceWmsApp2.get("/migration/count-snbn-movement", {
        headers: {
            'Authorization': `${token}` 
        }
    });
};

// SNBN NBN Movement to serial_number_movement table
export const snBnMovement = (token, state, limit) => {
    return instanceWmsApp2.post("/migration/snbn-movement-process", {
        state: state,
        limit: limit,
    }, {
        headers: {
            'Authorization': `${token}` 
        }
    });
};
