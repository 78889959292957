export const LIMIT_EXEC = 50000;
export const LIMIT_EXEC_IM = 50000;

export const STATUS = {
    SUCCESS: 'success',
    ERROR: 'error',
};

export const MESSAGES = {
    DATA_MIGRATION_EXIST: 'Data sudah pernah melakukan migrasi'
};

export const STEP_PROCESS = {
    WAITING: 'Waiting',
    PROCESSING: 'Processing',
    FAILED: 'Failed',
    FINISHED: 'Finished',
    RETRY: 'Retry',
    SKIP: 'Skip',
    PASS: 'PASS',
    PROGRESS: 'Progress',
};
  
export const LABEL = {
    DATA_BEFORE_MIGRATION: 'Ambil data sebelum migrasi',
    TOTAL_SKU: 'Total SKU',
    TOTAL_PO: 'Total Po',
    TOTAL_TRANSFER: 'Total Transfer Draft',
    TOTAL_TRANSFER_TRANSIT: 'Total Transfer Transit',
    TOTAL_TRANSFER_SHIPPED: 'Total Transfer Sudah Sampai',
    TOTAL_SALES_RETURN: 'Total Sales Return',
    TOTAL_ONLINE_RETURN_REJECTED: 'Total Return Online Tab Ditolak',
    TOTAL_ONLINE_RETURN_APPROVED: 'Total Return Online Tab Disetujui / Selesai',
    TOTAL_BILL: 'Total Bill',
    TOTAL_INVOICE: 'Total Invoice',
    TOTAL_ADJUSTMENT: 'Total Adjustment',
    TOTAL_PURCHASE_RETURN: '',
    TOTAL_ORDER_NOT_PAID: 'Total Pesanan Menunggu Bayaran',
    TOTAL_ORDER_CANCEL: 'Total Pesanan Batal',
    TOTAL_ORDER_READY_TO_PICK: 'Total Pesanan Siap Diproses',
    TOTAL_ORDER_CONFIRM_PICK: 'Total Pesanan Dalam Proses',
    TOTAL_ORDER_READY_TO_SHIP: 'Total Pesanan Siap Kirim Belum ada Resi',
    TOTAL_ORDER_SHIPPED: 'Total Pesanan Siap Kirim Sudah ada Resi dan Sudah Dikirim',
    TOTAL_ORDER_COMPLETED: 'Total Pesanan Selesai',
    TOTAL_ORDER_FAILED: 'Total Pesanan Gagal Download',

    MIGRATE_TO_APP: 'Migrasikan Tenant ke App 1',
    TURN_OFF_INTEGRATION: 'Non Aktifkan Integrasi',
    SIGNED_AS_WMS: 'Berikan Flag Tenant Sebagai WMS',
    WMS_MIGRATION_PROCESS: 'Migrasikan Tenant Sebagai WMS',
    DEFAULT_BIN_ITEM_MOVEMENT_PROCESS: 'Ubah Semua Transaksi Item Movement Menggunakan Rak Default',
    DEFAULT_BIN_PROCESS: 'Ubah Semua Transaksi Detail Menggunakan Rak Default',
    EMPLOYEE_MAPPING: 'Masukkan Data-Data Pegawai',
    ORDER_MAPPING_TO_WMS_STATUS: 'Mapping Semua Pesanan ke Status WMS',
    BIN_CHECK_IN_TRANSACTION: 'Pengecekan Rak di Transaksi',
    SNBN_MAPPING_TO_SNPSN: 'Mapping Semua Transaksi SN/BN ke SN/PSN',
    SNBN_MOVE_TO_PSN: 'Pindahkan Semua Transaksi SN/BN ke Serial Number Movement',

    BILL_CHECK: 'Tagihan Pembelian',
    ADJUSTMENT_CHECK: 'Penyesuaian Persediaan',
    PURCHASE_RETURN_CHECK: 'Retur Pembelian',
    ORDER_CHECK: 'Penjualan',
    ORDER_ITEM_BUNDLE_CHECK: 'Penjualan Item Bundle',
    SALES_RETURN_CHECK: 'Retur Penjualan',
    TRANSFER_CHECK: 'Transfer',
    ARCHIVE_ITEM_CHECK: 'Arsip',
    SNBN_RACK_CHECK: 'SN/BN',
    SNBN_ILLEGAL_CHECK: 'SN/BN ILLEGAL',

    DEFAULT_BIN_CHECK: 'Pengecekan Rak Default',
    DATA_AFTER_MIGRATION: 'Ambil data setelah migrasi',

    BILL_READONLY_CHECK: 'Cek Transaksi Tagihan Tergembok di Gudang',
    TRANSFER_READONLY_CHECK: 'Cek Transaksi Transfer Tergembok di Gudang',
    SALES_RETURN_READONLY_CHECK: 'Cek Transaksi Retur Penjualan Tergembok di Gudang',
    ENABLE_TRIGGER_CHECK: 'Cek Enable Table Trigger',
    UPDATE_ACCOUNT_SUPPORT: 'Ubah Akun support@jubelio.com menjadi support2@jubelio.com',
    TURN_ON_INTEGRATION: 'Pengaktifan Integrasi yang Sebelumnya Aktif',
    TURN_ON_SYNC_ORDER: 'Pengaktifan Sinkronisasi Pesanan',

    CHECK_SHIPPED_ORDER_APP_WITH_READY_TO_SHIP_APP2: 'Cek Selisih Pesanan Sudah Dikirim (app) dengan Siap Kirim (app2)',
    CHECK_DUPLICATE_READY_TO_SHIP_WITH_SHIPPED_ORDER: 'Cek Duplikat Siap Kirim dan Sudah Dikirim',
    CHECK_WAREHOUSE_STATUS_READY_TO_SHIP_NOT_SHOW_READY_TO_SHIP: 'Cek Status Gudang Siap Kirim yang Tidak Ditampilkan Dalam Pengiriman',
};

export const STEPS = {
    // Step 1
    DATA_BEFORE_MIGRATION: 'data_before_migration',
    TOTAL_SKU_BEFORE_MIGRATION: 'total_sku_before_migration',
    TOTAL_PO_BEFORE_MIGRATION: 'total_po_before_migration',
    TOTAL_TRANSFER_BEFORE_MIGRATION: 'total_transfer_before_migration',
    TOTAL_TRANSFER_TRANSIT_BEFORE_MIGRATION: 'total_transfer_transit_before_migration',
    TOTAL_TRANSFER_SHIPPED_BEFORE_MIGRATION: 'total_transfer_shipped_before_migration',
    TOTAL_SALES_RETURN_BEFORE_MIGRATION: 'total_sales_return_before_migration',
    TOTAL_ONLINE_RETURN_REJECTED_BEFORE_MIGRATION: 'total_online_return_rejected_before_migration',
    TOTAL_ONLINE_RETURN_APPROVED_BEFORE_MIGRATION: 'total_online_return_approved_before_migration',
    TOTAL_BILL_BEFORE_MIGRATION: 'total_bill_before_migration',
    TOTAL_INVOICE_BEFORE_MIGRATION: 'total_invoice_before_migration',
    TOTAL_ADJUSTMENT_BEFORE_MIGRATION: 'total_adjustment_before_migration',
    TOTAL_PURCHASE_RETURN_BEFORE_MIGRATION: 'total_purchase_return_before_migration',
    TOTAL_ORDER_NOT_PAID_BEFORE_MIGRATION: 'total_order_not_paid_before_migration',
    TOTAL_ORDER_CANCEL_BEFORE_MIGRATION: 'total_order_cancel_before_migration',
    TOTAL_ORDER_READY_TO_PICK_BEFORE_MIGRATION: 'total_order_ready_to_pick_before_migration',
    TOTAL_ORDER_CONFIRM_PICK_BEFORE_MIGRATION: 'total_order_confirm_pick_before_migration',
    TOTAL_ORDER_READY_TO_SHIP_BEFORE_MIGRATION: 'total_order_ready_to_ship_before_migration',
    TOTAL_ORDER_SHIPPED_BEFORE_MIGRATION: 'total_order_shipped_before_migration',
    TOTAL_ORDER_COMPLETED_BEFORE_MIGRATION: 'total_order_completed_before_migration',
    TOTAL_ORDER_FAILED_BEFORE_MIGRATION: 'total_order_failed_before_migration',

    // Step 2
    MIGRATE_TO_APP: 'migrate_to_app',
    TURN_OFF_INTEGRATION: 'turn_off_integration',
    SIGNED_AS_WMS: 'signed_as_wms',
    WMS_MIGRATION_PROCESS: 'wms_migration_process',
    DEFAULT_BIN_ITEM_MOVEMENT_PROCESS: 'default_bin_item_movement_process',
    DEFAULT_BIN_PROCESS: 'default_bin_process',
    EMPLOYEE_MAPPING: 'employee_mapping',
    ORDER_MAPPING_TO_WMS_STATUS: 'order_mapping_to_wms_status',
    BIN_CHECK_IN_TRANSACTION: 'bin_check_in_transaction',
    SNBN_MAPPING_TO_SNPSN: 'snbn_mapping_to_snpsn',
    SNBN_MOVE_TO_SNM: 'snbn_move_to_snm',

    // Step 3
    BILL_CHECK: 'bill_check',
    ADJUSTMENT_CHECK: 'adjustment_check',
    PURCHASE_RETURN_CHECK: 'purchase_return_check',
    ORDER_CHECK: 'order_check',
    ORDER_ITEM_BUNDLE_CHECK: 'order_item_bundle_check',
    SALES_RETURN_CHECK: 'sales_return_check',
    TRANSFER_CHECK: 'transfer_check',
    ARCHIVE_ITEM_CHECK: 'archive_item_check',
    SNBN_RACK_CHECK: 'snbn_rack_check',
    SNBN_ILLEGAL_CHECK: 'snbn_illegal_check',

    // Step 4
    DEFAULT_BIN_CHECK: 'default_bin_check',
    DATA_AFTER_MIGRATION: 'data_after_migration',

    // Step 5
    TOTAL_SKU_AFTER_MIGRATION: 'total_sku_after_migration',
    TOTAL_PO_AFTER_MIGRATION: 'total_po_after_migration',
    TOTAL_TRANSFER_AFTER_MIGRATION: 'total_transfer_after_migration',
    TOTAL_TRANSFER_TRANSIT_AFTER_MIGRATION: 'total_transfer_transit_after_migration',
    TOTAL_TRANSFER_SHIPPED_AFTER_MIGRATION: 'total_transfer_shipped_after_migration',
    TOTAL_SALES_RETURN_AFTER_MIGRATION: 'total_sales_return_after_migration',
    TOTAL_ONLINE_RETURN_REJECTED_AFTER_MIGRATION: 'total_online_return_rejected_after_migration',
    TOTAL_ONLINE_RETURN_APPROVED_AFTER_MIGRATION: 'total_online_return_approved_after_migration',
    TOTAL_BILL_AFTER_MIGRATION: 'total_bill_after_migration',
    TOTAL_INVOICE_AFTER_MIGRATION: 'total_invoice_after_migration',
    TOTAL_ADJUSTMENT_AFTER_MIGRATION: 'total_adjustment_after_migration',
    TOTAL_PURCHASE_RETURN_AFTER_MIGRATION: 'total_purchase_return_after_migration',
    TOTAL_ORDER_NOT_PAID_AFTER_MIGRATION: 'total_order_not_paid_after_migration',
    TOTAL_ORDER_CANCEL_AFTER_MIGRATION: 'total_order_cancel_after_migration',
    TOTAL_ORDER_READY_TO_PICK_AFTER_MIGRATION: 'total_order_ready_to_pick_after_migration',
    TOTAL_ORDER_CONFIRM_PICK_AFTER_MIGRATION: 'total_order_confirm_pick_after_migration',
    TOTAL_ORDER_READY_TO_SHIP_AFTER_MIGRATION: 'total_order_ready_to_ship_after_migration',
    TOTAL_ORDER_SHIPPED_AFTER_MIGRATION: 'total_order_shipped_after_migration',
    TOTAL_ORDER_COMPLETED_AFTER_MIGRATION: 'total_order_completed_after_migration',
    TOTAL_ORDER_FAILED_AFTER_MIGRATION: 'total_order_failed_after_migration',

    // Step 6
    BILL_READONLY_CHECK: 'bill_readonly_check',
    TRANSFER_READONLY_CHECK: 'transfer_readonly_check',
    SALES_RETURN_READONLY_CHECK: 'sales_return_readonly_check',
    ENABLE_TRIGGER_CHECK: 'enable_trigger_check',
    UPDATE_ACCOUNT_SUPPORT: 'update_account_support',
    TURN_ON_INTEGRATION: 'turn_on_integration',
    TURN_ON_SYNC_ORDER: 'turn_on_sync_order'
};
  
