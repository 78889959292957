import React, { Fragment, useRef } from 'react';
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Form, FormGroup } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { 
  HandleChangeStateMigrate, 
  setStepMigration, 
  HandleUpdateStepMigration
} from '../../../redux/migration/migrateActions';
import { HandleChangeStateGlobal } from '../../../redux/global/globalActions';
import PrintReport from './Print';
import Block1 from './Block1';
import Block2 from './Block2';
import Block3 from './Block3';
import Block4 from './Block4';
import { getDataWmsMigration, insertWmsMigration } from '../../../service/migration';
import { USERNAME } from '../../../service/variable';
import { 
  STEP_PROCESS,
  MESSAGES 
} from '../../../helpers/constants'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faCog,
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
library.add(
  faCog,
);

const Index = () => {
  let componentRef = useRef();

  const stateGlobal = useSelector((state) => state.global);
  const stateMigration = useSelector((state) => state.migration);
  const dispatch = useDispatch();

  const mappingPayload = () => {
    const { stepMigration } = stateMigration;
    let mapping = {
      created_by: USERNAME,
      step_migration: {
        // Step 1
        data_before_migration: stepMigration.dataBeforeMigration,
        total_sku_before_migration: stepMigration.totalSkuBeforeMigration,
        total_po_before_migration: stepMigration.totalPoBeforeMigration,
        total_transfer_before_migration: stepMigration.totalTransferBeforeMigration,
        total_transfer_transit_before_migration: stepMigration.totalTransferTransitBeforeMigration,
        total_transfer_shipped_before_migration: stepMigration.totalTransferShippedBeforeMigration,
        total_sales_return_before_migration: stepMigration.totalSalesReturnBeforeMigration,
        total_online_return_rejected_before_migration: stepMigration.totalOnlineReturnRejectedBeforeMigration,
        total_online_return_approved_before_migration: stepMigration.totalOnlineReturnApprovedBeforeMigration,
        total_bill_before_migration: stepMigration.totalBillBeforeMigration,
        total_invoice_before_migration: stepMigration.totalInvoiceBeforeMigration,
        total_adjustment_before_migration: stepMigration.totalAdjustmentBeforeMigration,
        total_purchase_return_before_migration: stepMigration.totalPurchaseReturnBeforeMigration,
        total_order_not_paid_before_migration: stepMigration.totalOrderNotPaidBeforeMigration,
        total_order_cancel_before_migration: stepMigration.totalOrderCancelBeforeMigration,
        total_order_ready_to_pick_before_migration: stepMigration.totalOrderReadyToPickBeforeMigration,
        total_order_confirm_pick_before_migration: stepMigration.totalOrderConfirmPickBeforeMigration,
        total_order_ready_to_ship_before_migration: stepMigration.totalOrderReadyToShipBeforeMigration,
        total_order_shipped_before_migration: stepMigration.totalOrderShippedBeforeMigration,
        total_order_completed_before_migration: stepMigration.totalOrderCompletedBeforeMigration,
        total_order_failed_before_migration: stepMigration.totalOrderFailedBeforeMigration,

        // Step 2
        migrate_to_app: stepMigration.migrateToApp,
        turn_off_integration: stepMigration.turnOffIntegration,
        signed_as_wms: stepMigration.signedAsWms,
        wms_migration_process: stepMigration.wmsMigrationProcess,
        default_bin_item_movement_process: stepMigration.defaultBinItemMovementProcess,
        default_bin_process: stepMigration.defaultBinProcess,
        employee_mapping: stepMigration.employeeMapping,
        order_mapping_to_wms_status: stepMigration.orderMappingToWmsStatus,
        bin_check_in_transaction: stepMigration.binCheckInTransaction,        
        snbn_mapping_to_snpsn: stepMigration.snBnMappingToSnPsn,
        snbn_move_to_snm: stepMigration.snBnMovetoSnm,

        // Step 3
        bill_check: stepMigration.billCheck,
        adjustment_check: stepMigration.adjustmentCheck,
        purchase_return_check: stepMigration.purchaseReturnCheck,
        order_check: stepMigration.orderCheck,
        order_item_bundle_check: stepMigration.orderItemBundleCheck,
        sales_return_check: stepMigration.salesReturnCheck,
        transfer_check: stepMigration.transferCheck,
        archive_item_check: stepMigration.archiveItemCheck,
        snbn_rack_check: stepMigration.snBnRackCheck,
        snbn_illegal_check: stepMigration.snBnIllegalCheck,
        total_snbn_illegal: stepMigration.totalSnbnIllegal,
        

        // Step 4
        default_bin_check: stepMigration.defaultBinCheck,
        data_after_migration: stepMigration.dataAfterMigration,

        // Step 5
        total_sku_after_migration: stepMigration.totalSkuAfterMigration,
        total_po_after_migration: stepMigration.totalPoAfterMigration,
        total_transfer_after_migration: stepMigration.totalTransferAfterMigration,
        total_transfer_transit_after_migration: stepMigration.totalTransferTransitAfterMigration,
        total_transfer_shipped_after_migration: stepMigration.totalTransferShippedAfterMigration,
        total_sales_return_after_migration: stepMigration.totalSalesReturnAfterMigration,
        total_online_return_rejected_after_migration: stepMigration.totalOnlineReturnRejectedAfterMigration,
        total_online_return_approved_after_migration: stepMigration.totalOnlineReturnApprovedAfterMigration,
        total_bill_after_migration: stepMigration.totalBillAfterMigration,
        total_invoice_after_migration: stepMigration.totalInvoiceAfterMigration,
        total_adjustment_after_migration: stepMigration.totalAdjustmentAfterMigration,
        total_purchase_return_after_migration: stepMigration.totalPurchaseReturnAfterMigration,
        total_order_not_paid_after_migration: stepMigration.totalOrderNotPaidAfterMigration,
        total_order_cancel_after_migration: stepMigration.totalOrderCancelAfterMigration,
        total_order_ready_to_pick_after_migration: stepMigration.totalOrderReadyToPickAfterMigration,
        total_order_confirm_pick_after_migration: stepMigration.totalOrderConfirmPickAfterMigration,
        total_order_ready_to_ship_after_migration: stepMigration.totalOrderReadyToShipAfterMigration,
        total_order_shipped_after_migration: stepMigration.totalOrderShippedAfterMigration,

        // Start Check Compare Data Order Shipped
        total_shipped_order_app_with_ready_to_ship_app2: stepMigration.totalShippedOrderAppWithReadyToShipApp2,
        total_duplicate_ready_to_ship_with_shipped_order: stepMigration.totalDuplicateReadyToShipWithShippedOrder,
        total_warehouse_status_ready_to_ship_not_show_in_ready_to_ship: stepMigration.totalWarehouseStatusReadyToShipNotShowInReadyToShip,
        // End Check Compare Data Order Shipped

        total_order_completed_after_migration: stepMigration.totalOrderCompletedAfterMigration,
        total_order_failed_after_migration: stepMigration.totalOrderFailedAfterMigration,

        // Step 6
        bill_readonly_check: stepMigration.billReadonlyCheck,
        transfer_readonly_check: stepMigration.transferReadonlyCheck,
        sales_return_readonly_check: stepMigration.salesReturnReadonlyCheck,
        enable_trigger_check: stepMigration.enableTriggerCheck,
        update_account_support: stepMigration.updateAccountSupport,
        turn_on_integration: stepMigration.turnOnIntegration,
        turn_on_sync_order: stepMigration.turnOnSyncOrder,
      },
    }

    return mapping;
  }

  const btnProcess = async () => {
    const { token, tempPayload } = stateMigration;
    
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Cek apakah data sudah sesuai atau belum!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Process',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "sweetalert-ok",
      },
      preConfirm: async (willSave) => {
        if (willSave) {
          return await getDataWmsMigration(token)
          .then((response) => {
            const { step_migration, migration_process } = response.data.data;

            step_migration.migration_process = migration_process;

            // Update state step migration
            dispatch(HandleUpdateStepMigration(step_migration));

            // Set State Step Migration
            dispatch(setStepMigration(tempPayload, step_migration, token));

            // Can Close Modal
            if (step_migration.update_account_support) {
              dispatch(HandleChangeStateGlobal({
                name: 'hideClose',
                value: false,
              }));
            }

            // Set State BtnProcess
            dispatch(
              HandleChangeStateMigrate({
                name: 'btnProcess',
                value: true,
              })
            );

            return response.data;
          }).catch(async (err) => {
            const { data } = err.response;
            
            if (data.statusCode === 404) {
              let mapping = mappingPayload();
              
              // Insert WMS Migration
              return await insertWmsMigration(mapping, token)
              .then((response) => {
                const { step_migration, migration_process } = response.data.data;

                step_migration.migration_process = migration_process;

                // Update state step migration
                dispatch(HandleUpdateStepMigration(step_migration));
                
                // Set State Step Migration
                dispatch(setStepMigration(tempPayload, step_migration, token));
                
                // Can Close Modal
                if (step_migration.update_account_support) {
                  dispatch(HandleChangeStateGlobal({
                    name: 'hideClose',
                    value: false,
                  }));
                }

                // Set State BtnProcess
                dispatch(
                  HandleChangeStateMigrate({
                    name: 'btnProcess',
                    value: true,
                  })
                );

                return response.data;
              }).catch(error => console.log(error));
            }
          })
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.statusCode === 200) {
          Swal.fire({
            title: 'Pemberitahuan',
            text: MESSAGES.DATA_MIGRATION_EXIST,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800,
          });
        }
      }
    })
  }
  
  return (
    <Fragment>
      <Card className="main-card mb-3">
        <CardBody>
          <CardTitle>Apakah Anda yakin ingin melakukan migrasi menjadi Tenant WMS dengan data tenant sbb: </CardTitle>
          <Form>
            <FormGroup row>
              <Col sm={2}>Company Name</Col>
              <Col sm={10}>: {stateMigration.companyName}</Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={2}>Owner Email</Col>
              <Col sm={10}>: {stateMigration.ownerEmail}</Col>
            </FormGroup>
          </Form>
        </CardBody>
        <CardFooter>
            <div>
                <Button size="lg" color="success" onClick={() => btnProcess()}
                  disabled={stateMigration.btnProcess === true ? true : false}
                >
                  {stateMigration.btnProcess === true ? (
                    <>
                      {stateMigration.stepMigration.turnOnSyncOrder === '' ? (
                        <><Spinner animation="border" size="sm"></Spinner> {STEP_PROCESS.PROCESSING}</>
                      ) : (
                        <>{STEP_PROCESS.FINISHED}</>
                      )}
                    </>
                  ) : 'Ya' }
                </Button>{' '}
                <Button size="lg" color="danger" className='ml-4' onClick={() => {
                  // Close modal
                  dispatch(HandleChangeStateGlobal({
                      name: 'modal',
                      value: !stateGlobal.modal,
                  }));
                  
                  // Clear token
                  dispatch(
                    HandleChangeStateMigrate({
                      name: 'token',
                      value: '',
                    })
                  );

                  // Clear State BtnProcess
                  dispatch(
                    HandleChangeStateMigrate({
                      name: 'btnProcess',
                      value: false,
                    })
                  );
                }} 
                disabled={stateMigration.btnProcess === true ? true : false}
              >Tidak</Button>
            </div>
        </CardFooter>
      </Card>
      {stateMigration.btnProcess === true && (
        <>
          {stateMigration.stepMigration.turnOnSyncOrder === '' && (
            <div className="font-icon-wrapper text-danger">
                <FontAwesomeIcon
                    icon={['fas', 'cog']}
                    spin
                    fixedWidth={false}
                    size="4x"
                />
                <p>Data Synchronization</p>
            </div>
          )}
          <Card className="main-card mb-3">
            <Block1 />
            <Block2 />
            <Block3 />
            <Block4 />        
          </Card>
          <Card className="main-card mb-3">
            <CardFooter>
              <div className='btn-center'>
                {stateMigration.stepMigration.turnOnSyncOrder === '' ? (
                  <Button outline className="mb-2 me-2 btn-transition" color="secondary"> <Spinner animation="border" size="sm"></Spinner> Status Migrasi Sedang Diproses</Button>
                ) : (
                  <>
                    <ReactToPrint
                      trigger={() => <Button outline className="mb-2 me-2 btn-transition" color="secondary">Cetak Laporan</Button>}
                      content={() => componentRef}
                    />
                    {/* component to be printed */}
                    <div style={{ display: "none" }}>
                      <PrintReport ref={(el) => (componentRef = el)} />
                    </div>                  
                  </>
                )}
              </div>
            </CardFooter>
          </Card>
        </>
      )}
    </Fragment>
  )
}

export default Index