import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { HandleChangeStateGlobal } from '../../redux/global/globalActions';
import { HandleChangeStateMigrate, HandleResetStateMigration } from '../../redux/migration/migrateActions';
import Migrate from './migrate'

const IndexModal = () => {
    const stateGlobal = useSelector((state) => state.global);
    const dispatch = useDispatch();

    return (
        <Modal
            className={" " + stateGlobal.sizeModal}
            isOpen={stateGlobal.modal}
            toggle={() => {
                dispatch(HandleChangeStateGlobal({
                    name: 'modal',
                    value: !stateGlobal.modal,
                }));
            }}
            backdrop="static"
        >
            <ModalHeader
                toggle={() => {
                    if (stateGlobal.hideClose === false) {
                        dispatch(HandleChangeStateGlobal({
                            name: 'modal',
                            value: !stateGlobal.modal,
                        }));

                        // Temporary is use by migrate
                        if (stateGlobal.typeModal === "migrate") {
                            // Clear token
                            dispatch(
                                HandleChangeStateMigrate({
                                    name: 'token',
                                    value: '',
                                })
                            );

                            dispatch(
                                HandleResetStateMigration()
                            )

                            // Clear State BtnProcess
                            dispatch(
                                HandleChangeStateMigrate({
                                    name: 'btnProcess',
                                    value: false,
                                })
                            );
                        }
                    }
                }}
            >
                {stateGlobal.titleModal}
            </ModalHeader>
            <ModalBody>
                <IndexChildModal type={stateGlobal.typeModal} />
            </ModalBody>
        </Modal>
    )
}

const IndexChildModal = ({ type }) => (
    <>
        {type === "migrate" && <Migrate />}
    </>
)

export default IndexModal;